import { Row, Col } from "react-bootstrap";

/* code splitting for performance */
import loadable from '@loadable/component'
const Returnsbar = loadable(() => import('../components/Returnsbar'))

export const fundDetailsState = {
  fundName: "",
  latestNav: "",
  latestNavDate: "",
  previousDayNav: "",
  previousDayNavDate: "",
  schemeType: "",
  isinCode: "",
  minInvestment: "",
  category: "",
  subCategory: "",
  launchDate: "",
  absReturns: {
    threeMonths: "",
    sixMonths: "",
    nineMonths: "",
    oneYear: "",
    threeYears: "",
    fiveYears: "",
  },
  yearlyCAGR: {
    oneYear: "",
    threeYear: "",
    fiveYear: "",
  },
  chartData: [],
  navChange: "",
};

export const spinnerState = {
  isLoading: false,
  loadingMsg: "Hey! Hold on, we're looking for your mutual fund...",
  animationClass: "text-focus-in",
};

export const waitMessages = [
  "You don't need a demat account to start mutual funds",
  "Not every mutual fund is made for long run",
  "You don't have to invest a lot of money in a mutual fund",
  "Hey! Hold on, we're looking for your mutual fund...",
];

export const getFundReturns = (loading, returnType, fundDetails) => {
  let fundReturns = [];
  if (returnType === "CAGR") {
    fundReturns.push(
      <span className="text-xs v-margin">In the last 3 months</span>,
      <Returnsbar return={0} />,
      <span className="text-xs v-margin">In the last 6 months</span>,
      <Returnsbar return={0} />,
      <span className="text-xs v-margin">In the last 1 year</span>,
      <Returnsbar return={Number(fundDetails.yearlyCAGR.one)} />,
      <span className="text-xs v-margin">In the last 3 years</span>,
      <Returnsbar return={Number(fundDetails.yearlyCAGR.three)} />,
      <span className="text-xs v-margin">In the last 5 years</span>,
      <Returnsbar return={Number(fundDetails.yearlyCAGR.five)} />
    );
  } else {
    fundReturns.push(
      <span className="text-xs v-margin">In the last 3 months</span>,
      <Returnsbar return={Number(fundDetails.absReturns.threeMonths)} />,
      <span className="text-xs v-margin">In the last 6 months</span>,
      <Returnsbar return={Number(fundDetails.absReturns.sixMonths)} />,
      <span className="text-xs v-margin">In the last 1 year</span>,
      <Returnsbar return={Number(fundDetails.absReturns.oneYear)} />,
      <span className="text-xs v-margin">In the last 3 years</span>,
      <Returnsbar return={Number(fundDetails.absReturns.threeYears)} />,
      <span className="text-xs v-margin">In the last 5 years</span>,
      <Returnsbar return={Number(fundDetails.absReturns.fiveYears)} />
    );
  }
  return fundReturns;
};

export const showSchemeDetails = (fundDetails) => {
  return (
    <>
      <h6>Scheme Details</h6>
      <br />
      <Row>
        <Col xs={12} md={6}>
          <p className="no-margin text-s">Scheme Category</p>
          <p>
            <b>{fundDetails.category}</b>
          </p>
        </Col>
        <Col xs={12} md={6}>
          <p className="no-margin text-s">Sub Category</p>
          <p>
            <b>{fundDetails.subCategory}</b>
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12} md={6}>
          <p className="no-margin text-s">Scheme Type</p>
          <p>
            <b>{fundDetails.schemeType}</b>
          </p>
        </Col>
        <Col xs={12} md={6}>
          <p className="no-margin text-s">Launch Date</p>
          <p>
            <b>{fundDetails.launchDate}</b>
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <p className="no-margin text-s">Min Investment</p>
          <p>
            <b>{fundDetails.minInvestment}</b>
          </p>
        </Col>
      </Row>
    </>
  );
};
