import { Row, Col } from "react-bootstrap";
import { getAMCLogo } from "../services/Utilities";

function NFOCard({ id, scheme, startDate, endDate, status }) {
  return (
    <div className="nfo-card">
      <a href={"/nfo/" + id}>
        <Row>
          <Col lg={2}>
            <img alt="amc-logo" src={getAMCLogo(scheme)} />
          </Col>
          <Col lg={9}>
            <h6>{scheme}</h6>
            <Row>
              <Col>
                {status === "open" ? (
                  <p>
                    <span className="open">
                      <i class="bi bi-unlock-fill"></i>
                    </span>
                    Subscription : {startDate} to {endDate}
                  </p>
                ) : (
                  <p>
                    <span className="closed">
                      <i class="bi bi-lock-fill"></i>
                    </span>
                    Closed on : {endDate}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </a>
    </div>
  );
}
export default NFOCard;
