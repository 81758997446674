import "../theme/styles.scss";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Alert, Container } from "react-bootstrap";

import API from "../api";

/* code splitting for performance */
import loadable from "@loadable/component";
const Header = loadable(() => import("../components/Header"));

function Verification() {
  let [searchParams] = useSearchParams();
  const [errorMessage, setErrorMesage] = useState("");
  const token = searchParams.get("token");
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (token) {
      API.verifyApplication(token)
        .then((result) => {
          if (result.status == 200) {
            let email = result.data.data.email;
            window.location.href = `${baseURL}/application/${email}`;
          } else {
            setErrorMesage("Your email verification failed");
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            setErrorMesage("Your email verification failed");
          }
        });
    } else {
      window.location.href = baseURL;
    }
  }, []);

  const showErrorMessage = () => {
    return errorMessage !== "" ? (
      <>
        <br />
        <Alert variant="danger">{errorMessage}</Alert>
      </>
    ) : null;
  };

  return (
    <>
      <Header />
      <Container>{showErrorMessage()}</Container>
    </>
  );
}

export default Verification;
