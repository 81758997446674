import "../theme/styles.scss";
import { Container } from "react-bootstrap";

/* code splitting for performance */
import loadable from '@loadable/component'
const Header = loadable(() => import('../components/Header'))
const Footer = loadable(() => import('../components/Footer'))

function Disclosure() {
  return (
    <>
      <Header page="disclosure" />
      <Container className="disclosure-section content">
        <h2>Commission Disclosure</h2><br/>
        <p>
          Kashly (Capsquare Technologies Private Limited) is a distributor of
          mutual fund and hence earn our commission as a product distributor.
          There is no platform charges or other hidden charges. As a part of our
          own philosophy we keep a very transparent and clear disclosure of our
          earnings for the understanding of our clients:
        </p>
        <br />
        <table>
          <tr>
            <th>Fund Category</th>
            <th>Commission Method</th>
            <th>Range</th>
            <th>Paid From</th>
          </tr>
          <tr>
            <td>Debt</td>
            <td>Trail</td>
            <td>0.05% to 1.25%</td>
            <td>Portfolio expenses charged by the AMC</td>
          </tr>
          <tr>
            <td>Equity</td>
            <td>Trail</td>
            <td>0.30% to 1.5%</td>
            <td>Portfolio expenses charged by the AMC</td>
          </tr>
          <tr>
            <td>Hybrid Funds</td>
            <td>Trail</td>
            <td>0.50% to 1.30%</td>
            <td>Portfolio expenses charged by the AMC</td>
          </tr>
          <tr>
            <td>Liquid Funds</td>
            <td>Trail</td>
            <td>0.02% to 0.15%</td>
            <td>Portfolio expenses charged by the AMC</td>
          </tr>
        </table>
        <br />
        <p>
          Portfolio expenses are capped at certain level from 1.5% to 2.75% by
          SEBI and is charged as per the asset size and on annualized
          calculation method. The net NAV announced by the fund under its regular
          plan is less of such charges taken on weekly average valuation of the
          portfolio assets.
        </p>
        <p>
          In case you need any more information, commission received for a
          specific fund or wish to understand more on expense ratio, email us at
          support@kashly.in
        </p>
      </Container>
      <Footer />
    </>
  );
}

export default Disclosure;
