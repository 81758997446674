import { useEffect, useState } from "react";
import "./Range.scss";

const Range = ({ min, max, current, step, label, name, handler }) => {
  const [value, setValue] = useState({
    minVal: 0,
    maxVal: 100,
    current: 50,
    step: 1,
    label: "",
    name: "",
  });

  useEffect(() => {
    setValue({
      ...value,
      minVal: min,
      maxVal: max,
      current: current,
      step: step,
      label: label ? label : "",
      name: name ? name : "",
    });
  }, [current]);

  const handleChange = (e) => {
    let val = e.target.value;
    setValue({
      ...value,
      current: rangeBoundedValue(val),
    });
  };

  const rangeBoundedValue = (val) => {
    if (val > value.maxVal) {
      return value.maxVal;
    } else {
      return val;
    }
  };
  return (
    <div class="range-container">
      <label>{value.label}</label>
      <label className="range-value">
        <input
          className="range-value-input"
          style={{ width: String(value.maxVal).length + 4 + "ch" }}
          type="number"
          min="0"
          value={value.current}
          name={value.name}
          onInput={(e) => handler(e)}
          onChange={handleChange}
        />
      </label>
      <input
        type="range"
        min={value.minVal}
        max={value.maxVal}
        value={value.current}
        step={value.step}
        className="range"
        name={value.name}
        onInput={(e) => handler(e)}
      />
    </div>
  );
};
export default Range;
