import { Row, Col, Container } from "react-bootstrap";
function Footer() {
  var date = new Date();
  var year = date.getFullYear();
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <br />
            <h4>Kashly</h4>
            <p>
              Copyright © {year} Capsquare Technologies Pvt. Ltd. All Rights
              Reserved Kashly (Capsquare Technologies Private Limited) is an
              AMFI registered distributor of Mutual Funds (ARN-186781)
            </p>

            <div className="social-icons">
              <a
                href="https://www.facebook.com/kashly.in"
                target="_blank"
                rel="noreferrer"
              >
                <i class="bi bi-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/kashly.in"
                target="_blank"
                rel="noreferrer"
              >
                <i class="bi bi-instagram"></i>
              </a>
              <a href="https://t.me/kashlyin" target="_blank" rel="noreferrer">
                <i class="bi bi-telegram"></i>
              </a>
              <a
                href="https://whatsapp.com/channel/0029VaC5euv5vKA685ycsQ1M"
                target="_blank"
                rel="noreferrer"
              >
                <i class="bi bi-whatsapp"></i>
              </a>
            </div>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <br />
            <h6>Support</h6>
            <ul>
              <li>
                <a href="/contact">Contact</a>
              </li>
              <li>
                <a href="/open-account">Open Account</a>
              </li>
              <li>
                <a href="/nri-account">NRI Investment</a>
              </li>

              <li>
                <a
                  href="https://www.amfiindia.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  AMFI Website
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <br />
            <h6>Quick Links</h6>
            <ul>
              <li>
                <a href="/about" alt="kashly about us">
                  About us
                </a>
              </li>
              <li>
                <a href="/calculators">Calculators</a>
              </li>
              <li>
                <a href="/nfo">New Fund Offers</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://kashly.in/read/category/mutual-funds/"
                  rel="noreferrer"
                >
                  Mutual Fund Articles
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <br />
            <h6>Terms of Use</h6>
            <ul>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/refund">Refund Policy</a>
              </li>
              <li>
                <a href="/terms">Terms and Conditions</a>
              </li>
            </ul>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <p>
              Mutual Fund Tools :{" "}
              <a href="/sip-calculator" target="_blank">
                SIP Calculator
              </a>
              {" | "}
              <a href="/swp-calculator" target="_blank">
                SWP Calculator
              </a>
              {" | "}
              <a href="/goal-calculator" target="_blank">
                Goal Calculator
              </a>
              {" | "}
              <a href="/inflation-calculator" target="_blank">
                Inflation Calculator
              </a>
            </p>
            <p>
              Mutual Fund investments are subject to market risks, read all
              scheme related documents carefully before investing. Past
              performance is not indicative of future performance.
              <br />
              <br />
              Kashly is not an order placement platform hence Kashly is not
              liable or responsible and does not represent or warrant for any
              damages regarding non-execution of orders or any incorrect
              execution of orders with regard to the funds that happens outside
              of our platform.
              <br />
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Footer;
