import React, { useState } from "react";
import { PopupModal } from "react-calendly";
import { Row, Col, Container, Button } from "react-bootstrap";
import "./Home.scss";

import { CALENDLY_LINK, WHATSAPP_CHANNEL } from "../constants";

/* code splitting for performance */
import loadable from "@loadable/component";

const Header = loadable(() => import("../../components/Header"));
const Footer = loadable(() => import("../../components/Footer"));

const Hero = loadable(() => import("./Hero"));
const Services = loadable(() => import("./Services"));
const Features = loadable(() => import("./Features"));
const Testimonial = loadable(() => import("./Testimonial"));
const FAQSection = loadable(() => import("./FAQSection"));



function Home() {
  const [show, setShow] = useState(false);

  return (
    <>
      <Header />
      <Hero />
      <Services />
      <Features />
      <Testimonial />
      <div className="registration-section">
        <Container className="content-wrapper content">
          <Row>
            <Col>
              <h1>Safe & Secure</h1>
              <div className="section-subtitle">
                Your money is always safe &amp; regulated by Asset Management
                Companies (AMC).
              </div>
            </Col>
          </Row>
          <Row>
            <div className="align-center">
              <div class="animated-section">
                <div className="grid-container">
                  <div className="grid-child animated-box">
                    <i class="bi bi-person"></i>
                    <p>You</p>
                  </div>
                  <div className="grid-child animated-box">
                    <i class="bi bi-safe"></i>
                    <p>Bank</p>
                  </div>
                  <div className="grid-child animated-box">
                    <i class="bi bi-bank"></i>
                    <p>AMC</p>
                  </div>
                </div>
                <div className="animated-line" />
              </div>
              <div className="amfi-box">
                <Row>
                  <Col md={2} lg={1}>
                    <i class="bi bi-shield-check"></i>
                  </Col>
                  <Col md={10} lg={11}>
                    Kashly is a certified distributor of mutual funds registered
                    with Association of Mutual Funds in India (AMFI) with Reg.
                    No
                    <b> 186781</b>. Kashly does not keep or handle your money.
                  </Col>
                </Row>
              </div>
              <Button size="lg" variant="primary" href="/open-account">
                Get FREE Account
              </Button>
            </div>
          </Row>
        </Container>
      </div>
      <div className="actions-section">
        <Container className="content-wrapper content">
          <Row>
            <Col md={12} lg={5}>
              <h1>Talk to our certified professionals</h1>
              <br />
              <p>
                You won’t be talking to pushy salesmen. You’ll only be engaging
                with NISM certified professionals. Good guidance takes time, you
                will have our undivided attention for at least 30 minutes.
              </p>
              <br />
              <Button
                size="lg"
                variant="outline-primary"
                onClick={() => setShow(true)}
              >
                Book Free Call
              </Button>
              <PopupModal
                url={CALENDLY_LINK}
                onModalClose={() => setShow(false)}
                open={show}
                rootElement={document.getElementById("root")}
              />
            </Col>
            <Col lg={2} className="d-none d-lg-block" />
            <Col id="nfo-section" md={12} lg={5}>
              <h1>Explore new funds in the market</h1>
              <br />
              <p>
                An NFO or New Fund Offer is when a mutual fund house launches a
                new mutual fund to the market for the first time. Just like
                stock market Initial Public Offerings.
              </p>
              <br />
              <Button size="lg" variant="outline-primary" href="/nfo">
                New Funds
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="features-section">
        <Container className="content-wrapper content">
          <Row>
            <Col>
              <h1>Join Whatsapp Channel</h1>
              <div className="section-subtitle">
                Join our whatsapp channel and learn more about mutual funds.
              </div>
              <br />
              <Button
                size="lg"
                variant="primary"
                href={WHATSAPP_CHANNEL}
              >
                <i className="bi bi-whatsapp" />
                &nbsp;&nbsp;Join Channel
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <FAQSection />
      <Footer />
    </>
  );
}

export default Home;

/*
<Row>
            <div className="align-center">
              <div class="animated-section">
                <div className="grid-container">
                  <div className="grid-child animated-box">
                    <i class="bi bi-person"></i>
                    <p>You</p>
                  </div>
                  <div className="grid-child animated-box">
                    <i class="bi bi-safe"></i>
                    <p>Bank</p>
                  </div>
                  <div className="grid-child animated-box">
                    <i class="bi bi-bank"></i>
                    <p>AMC</p>
                  </div>
                </div>
                <div className="animated-line" />
              </div>
              <div className="amfi-box">
                <Row>
                  <Col md={2} lg={1}>
                    <i class="bi bi-shield-check"></i>
                  </Col>
                  <Col md={10} lg={11}>
                    Kashly is a certified distributor of mutual funds registered
                    with Association of Mutual Funds in India (AMFI) with Reg.
                    No
                    <b> 186781</b>. Kashly does not keep or handle your money.
                  </Col>
                </Row>
              </div>
              <Button size="lg" variant="primary" href="/open-account">
                Get FREE Account
              </Button>
            </div>
          </Row>*/
