import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "./Searchbar.scss";

function Searchbar() {
  const navigate = useNavigate();
  const [items, setItems] = useState();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://kashly.in/assets/jsons/funds.js?v=" + Date.now();
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      setItems(window.autoCompleteData);
    });
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleOnSelect = (item) => {
    navigate("/funds/" + item["id"]);
  };
  return (
    <div className="search-bar">
      <ReactSearchAutocomplete
        items={items}
        onSelect={handleOnSelect}
        autoFocus
        placeholder="Search Mutual Funds Here..."
        styling={{
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "2rem",
          boxShadow: "none",
          fontSize: "16px",
          fontFamily: "Inter, sans-serif",
          zIndex: 1000,
        }}
      />
    </div>
  );
}
export default Searchbar;
