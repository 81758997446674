import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

function NavChart(props) {
  const NavChartTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="nav-chart-tooltip">
          <p className="nav-value">&#8377;{payload[0].payload.nav}</p>
          <p className="nav-date">
            {moment(payload[0].payload.date, "DD-MM-YYYY").format(
              "MMM DD, YYYY"
            )}
          </p>
        </div>
      );
    }
    return null;
  };
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        width={500}
        height={400}
        data={props.data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="2 1" />
        <XAxis dataKey="dispDate" tick={{ fontSize: "12px" }} />
        <YAxis
          hide="true"
          tick={{ fontSize: "12px", dx: -10 }}
          type="number"
          domain={[(dataMin) => dataMin - 5, (dataMax) => dataMax + 5]}
          allowDataOverflow={true}
        />
        <Tooltip animationDuration={100} content={<NavChartTooltip />} />
        <Line
          type="monotone"
          dataKey="nav"
          stroke="rgb(34, 51, 104)"
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
export default NavChart;
