import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";

import { mapStateToAPI, isMinorDOB } from "./utils";
import { nomineeOptions, guardianOptions } from "./options";
import { validateNominee } from "../validations";

import Field from "./Field";
import SectionHeader from "./SectionHeader";
import API from "../../api";
import { BANK_FORM, DOCUMENT_FORM } from "../constants";

const Nominee = ({ application, setApplication }) => {
  const nomineeRecords = application.nomineeDetails.nomineeRecord;
  const [errorMessage, setErrorMesage] = useState("");
  const [nominees, setNominees] = useState([
    {
      nomineeName: "",
      dob: "",
      relation: "",
      allocation: 100,
      guardianName: "",
      guardianRelation: "",
      guardianDOB: "",
    },
  ]);

  useEffect(() => {
    let newState = [];
    if (nomineeRecords.length > 0) {
      nomineeRecords.map((nominee) => {
        newState.push({
          nomineeName: nominee.nomineeName,
          dob: nominee.dob,
          relation: nominee.relation,
          allocation: nominee.allocation,
          guardianName: nominee.guardianName,
          guardianDOB: nominee.guardianDOB,
          guardianRelation: nominee.guardianRelation,
        });
      });
      setNominees(newState);
    }
  }, [application]);

  const showErrorMessage = () => {
    return errorMessage !== "" ? (
      <Alert variant="danger">{errorMessage}</Alert>
    ) : null;
  };

  const handleFieldChange = (index, event) => {
    let data = [...nominees];
    data[index][event.target.name] = event.target.value;
    if (event.target.name == "dob") {
      data[index]["guardianDOB"] = "";
      data[index]["guardianName"] = "";
      data[index]["guardianRelation"] = "";
    }
    setNominees(data);
  };

  const handleAddNominee = () => {
    if (nominees.length <= 2) {
      let newNominee = {
        nomineeName: "",
        dob: "",
        relation: "",
        allocation: 0,
        guardianName: "",
        guardianRelation: "",
        guardianDOB: "",
      };
      setNominees([...nominees, newNominee]);
    } else {
      setErrorMesage("Only three nominees are allowed in the application.");
    }
  };

  const handleRemoveNominee = (index) => {
    if (nominees.length > 1) {
      let data = [...nominees];
      data.splice(index, 1);
      setNominees(data);
    } else {
      setErrorMesage(
        "At least one nominee is mandatory as per SEBI regulations."
      );
    }
  };

  const handleContinue = () => {
    let validationError = validateNominee(nominees);
    if (validationError == "") {
      let newState = {
        ...application,
        form: DOCUMENT_FORM,
        nomineeDetails: {
          nomineeRecord: nominees,
        },
      };
      setApplication(newState);
      modifyApplication(newState);
    }
    setErrorMesage(validationError);
  };

  const handleBack = () => {
    let newState = {
      ...application,
      form: BANK_FORM,
    };
    setApplication(newState);
  };

  const modifyApplication = (state) => {
    API.updateApplication(application.id, mapStateToAPI(state))
      .then((result) => {})
      .catch((error) => {});
  };

  return (
    <Container className="application-wrapper">
      <SectionHeader
        step="4"
        heading="Nominee Details"
        subheading="As per SEBI rules, nomination is a mandatory process. More than one nominee is optional."
      />
      {nominees.map((nominee, index) => {
        return (
          <div id="nominee-container" key={index}>
            <div id="nominee-header">
              <div id="nominee-title">
                <i class="bi bi-person"></i> Nominee {index + 1}{" "}
              </div>
              <div id="nominee-action-area">
                <Button
                  size="sm"
                  variant="outline-dark"
                  onClick={() => handleRemoveNominee(index)}
                >
                  <i class="bi bi-trash"></i> Remove
                </Button>
              </div>
            </div>
            <div id="nominee-body">
              <Row>
                <Col lg={3} sm={12}>
                  <Field
                    type="text"
                    name="nomineeName"
                    label="Name"
                    value={nominee.nomineeName}
                    onChange={(event) => handleFieldChange(index, event)}
                  />
                </Col>
                <Col lg={3} sm={12}>
                  <Field
                    type="select"
                    name="relation"
                    label="Relation"
                    options={nomineeOptions()}
                    value={nominee.relation}
                    onChange={(event) => handleFieldChange(index, event)}
                  />
                </Col>
                <Col lg={3} sm={12}>
                  <Field
                    type="date"
                    name="dob"
                    label="Date of birth"
                    value={nominee.dob}
                    onChange={(event) => handleFieldChange(index, event)}
                  />
                </Col>
                <Col lg={3} sm={12}>
                  <Field
                    type="number"
                    name="allocation"
                    label="Percentage"
                    value={nominee.allocation}
                    onChange={(event) => handleFieldChange(index, event)}
                  />
                </Col>
              </Row>
              {isMinorDOB(nominee.dob) ? (
                <>
                  <Row>
                    <Col>
                      <Alert variant="warning">
                        Guardian details are mandatory to nominate minors for
                        mutual fund investments.
                      </Alert>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} sm={12}>
                      <Field
                        type="text"
                        name="guardianName"
                        label="Guardian Name"
                        value={nominee.guardianName}
                        onChange={(event) => handleFieldChange(index, event)}
                      />
                    </Col>
                    <Col lg={3} sm={12}>
                      <Field
                        type="select"
                        name="guardianRelation"
                        label="Guardian Relation"
                        options={guardianOptions()}
                        value={nominee.guardianRelation}
                        onChange={(event) => handleFieldChange(index, event)}
                      />
                    </Col>
                    <Col lg={3} sm={12}>
                      <Field
                        type="date"
                        name="guardianDOB"
                        label="Guardian Date of birth"
                        value={nominee.guardianDOB}
                        onChange={(event) => handleFieldChange(index, event)}
                      />
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
          </div>
        );
      })}

      {showErrorMessage()}
      <Row>
        <Col>
          <Button onClick={handleBack}>Back</Button>
          <Button onClick={handleContinue}>Continue</Button>
          <Button variant="outline-primary" onClick={handleAddNominee}>
            + Add Nominee
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export default Nominee;
