import "../theme/styles.scss";
import { Container } from "react-bootstrap";

/* code splitting for performance */
import loadable from '@loadable/component'
const Header = loadable(() => import('../components/Header'))
const Footer = loadable(() => import('../components/Footer'))

function Terms() {
  return (
    <>
      <Header page="terms" />
      <Container>
        <br />
        <br />
        <h4>Kashly application usage and service agreement</h4>
        <p>
          Kashly is owned by capsquare technologies private limited
          <br />
          Date of last revision: <b>August 26, 2023</b>
          <br />
        </p>
        <br />
        <p style={{ fontSize: "14px" }}>
          This application usage and service (“<b>Agreement</b>”) is made and
          entered into:
          <br />
          <b>BETWEEN</b>
          <ol>
            <li>
              <b>KASHLY</b> on behalf of{" "}
              <b>Capsquare Technologies Private Limited</b>, having its
              principal place of business at Door No 4/461, Second Floor, Valamkottil Towers, Judgemukku, Thrikkakara P.O, Kakkanad, Kochi, Kerala (hereinafter
              referred to as “KASHLY”);
              <b> AND</b>
            </li>
            <li>
              <b>You</b> (hereinafter collectively referred to as " 
              <b>Client</b> " or “ <b>You</b> ”). If you are entering into this
              Agreement on behalf of a legal entity you represent that you have
              the authority to bind such entity to these terms and conditions,
              in which case the term "Client" or “You” shall refer to such
              entity.{" "}
            </li>
          </ol>
          <b>WHEREAS:</b>
          <br />
          KASHLY is engaged in providing mutual fund platform services for the
          investors mapped under ARN-186781. NOW, THEREFORE, for and in
          consideration of the mutual promises, benefits and covenants contained
          herein, KASHLY and You hereby agree as follows:
        </p>
        <br />
        <p style={{ fontSize: "14px" }}>
          <ol>
            <li>
              <h6>Using Our Services</h6>
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  You may make use of our Services through our online web
                  portal, mobile app; other online mechanisms and through
                  interaction with our relationship managers.
                </li>
                <li>
                  Kashly complies with all regulations and compliances as per
                  Indian law. If you are accessing our Services from any
                  jurisdiction outside India, the onus of compliance with the
                  local laws lies on you.
                </li>
                <li>
                  Some of the Services, provided by us or our Service Partners,
                  may have restrictions on use depending on criteria such as,
                  but not limited to, your place of residence, nationality, age,
                  wealth, source of income/wealth etc. You agree to provide
                  accurate information for us and our Service Partners to comply
                  with such restrictions.
                </li>
                <li>
                  If you have provided us with your contact details, such as
                  email or phone number, we may send you announcements, messages
                  or information on our Services and how to use them. You may
                  opt out of some of these messages unless they are necessary
                  for the delivery of Services; for compliance with regulations;
                  or, for information security, for e.g., password change alerts
                  cannot be opted out of.
                </li>
                <li>
                  You agree that in the event the Services rendered by Kashly
                  under this Agreement are availed by multiple persons, then,
                  Client 1 will be designated as the primary contact and
                  appointed as the single point of contact by the signatories of
                  this Agreement. The primary contact shall communicate with
                  Kashly with respect to this Agreement and any other matters
                  pertaining to the Client’s accounts. Any change in the
                  designated primary contact shall forthwith be communicated to
                  Kashly, in writing. In case of a legal entity, the primary
                  contact shall be the person duly authorised by the legal
                  entity to sign this agreement.
                </li>
                <li>
                  Your clicking on the “I accept” checkbox is the legal
                  equivalent of your signing this Agreement and accepting the
                  terms thereof. Utilisation of certain Services shall require
                  your additional consent which shall be recorded as and when
                  you choose to utilise such Services. Any additional details or
                  information regarding provision of any Services, as required
                  by Applicable Law, shall be displayed to you prior to your
                  utilisation of any such services.
                </li>
                <li>
                  Please don’t misuse, disrupt, interrupt, destroy or limit the
                  functionality of our Services; or try to access them using a
                  method other than the interface and the instructions that we
                  provide. If you do so, we may stop providing our Services to
                  you and pursue legal action against you.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <h6>Identity & Authentication</h6>
              <br />
              Kashly will establish your identity on its online Services by
              means of a phone number or email and will authenticate it by
              either a password selected by you, or a onetime password (OTP)
              sent to your email or mobile phone, or third party authentication
              services provided by third parties such as Google and Facebook, or
              biometric identification enabled by your device. Any instructions
              provided to, or communication with, Kashly or its Service Partners
              which are so authenticated will be considered to have originated
              from you.
            </li>
            <br />
            <li>
              <h6>Choice of Mutual fund Distribution services</h6>
              <br />
              All clients of Capsquare technologies private limited, availing
              mutual fund distribution services will be authorized to use Kashly
              application.
              <br />
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  You may need to meet certain threshold requirements, as
                  described in the description of Services, to be a client.
                  Kashly reserves the right to add certain stipulations for
                  offering services for the choice made by you and these will be
                  added in relevant annexures.
                </li>
                <li>
                  You will be able to use Kashly platform and other services
                  offered by the platform until you use the distribution service
                  offered. The choice of your subscription shall define the
                  range & scope of Services which shall be made available to you
                  in accordance with applicable laws.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <h6>Third Party Service Partners</h6>
              <br />
              For the provision of Services, Kashly may utilise third party
              service partners (“Service Partners”) other than Kashly. The use
              and operation of any integrated services from our Service Partners
              such as investment advisory, portfolio management, investing,
              borrowing, insurance, data aggregation, etc. will be additionally
              governed by the relevant service terms and contracts of the
              respective Service Partners. Kashly shall not be liable for any
              penalties, loss or damage which is incurred by You due to the
              negligence or actions of any Service Partner of Kashly and not
              caused by any action of Kashly.
            </li>
            <br />
            <li>
              <h6>Provision of Services</h6>
              <br />
              You acknowledge and agree that, for the provision of Services
              under this Agreement:
              <br />
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Kashly shall carry out all activities that are required to
                  fulfil its regulatory obligations for the provision of
                  Services under this Agreement.
                </li>
                <li>
                  Your access to our Services is associated with a username and
                  password (together “Login Credentials”) and Kashly will
                  consider the instructions placed with the use of your Login
                  Credentials to have originated from You. You are solely
                  responsible for the security of your Login Credentials. Kashly
                  shall not be held liable for carrying out any instructions
                  placed from your account utilising your Login Credentials
                  which may be unauthorized due to any reason including an
                  information leak or security breach on your part.
                </li>
                <li>
                  Kashly may utilise third party vendors (“Vendors”) for support
                  in provision of Services.
                </li>
                <li>
                  Kashly, or its Vendors, may
                  <ol style={{ listStyleType: "lower-roman" }}>
                    <li>
                      contact you on issues relating to provision of Services;
                    </li>
                    <li>
                      communicate with its Service Partners or Manufacturers and
                      their authorized service providers on your behalf for
                      financial or non-financial transactions; and
                    </li>
                    <li>
                      record all your interactions with them including phone
                      calls, chats, conversations and emails, with its employees
                      for legal compliance, security, employee training, and
                      other lawful purposes.
                    </li>
                  </ol>
                </li>
                <li>
                  Any sort of graphical representations, recommendations,
                  feedback and reviews, provided on the investment platform, are
                  in no way a guarantee for the performance of the securities,
                  mutual funds and other financial products.
                </li>
                <li>
                  Other than as expressly set out in this Agreement, neither
                  Kashly nor any of its Service Partners, as the case may be,
                  make any specific promises about the Services provided. The
                  Services are provided ‘as is’.
                </li>
                <li>
                  Your bank may charge you a fee for facilitating transactions,
                  or for failing to maintain sufficient balance to make an
                  investment from your bank account. Kashly does not levy nor
                  does it receive those charges.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <h6>Grievance Redressal & Communication</h6>
              <br />
              Kashly provides multiple channels for you to request for support
              or to communicate any grievances you may have:
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>By sending an email to support@kashly.in</li>
                <li>By phone on +91 9562050863.</li>
              </ol>
              <br />
              Any of your queries & requests shall be responded to within 2
              (two) working days of receipt. Resolution, appropriate and to your
              satisfaction shall be provided as soon as possible depending upon
              the nature of the request.
              <br />
              <br />
              If you are not satisfied by the resolution provided, you may
              escalate your grievance to contat@capsquare.in
            </li>
            <br />
            <li>
              <h6>Privacy and Data Protection</h6>
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Under no circumstances will we sell or rent your personal
                  information to anyone, for any reason, at any time.
                </li>
                <li>
                  Kashly shall use your data specifically for the provision of
                  Services you have subscribed to, and in accordance with the
                  Privacy Policy set out in this Agreement.
                </li>
                <li>
                  You provide your explicit consent to share your data across
                  the entities comprising the Capsquare technologies pvt ltd to
                  the extent required for provision of the Services, and as may
                  be required to comply with any regulatory requirements.
                </li>
                <li>
                  Where the Services under this Agreement are being availed
                  together as a family by multiple Clients, each Client provides
                  explicit consent to Kashly to share their data with the other
                  Clients who are signatories under this Agreement for the
                  purpose of provision of Services.
                </li>
                <li>
                  Kashly may use the unique identifier of your device for
                  security purposes to track suspicious activity and for
                  enabling certain features. For instance, if we detect that
                  your account is being accessed from multiple devices, we may
                  contact you to ensure that you have authorised such access.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <h6>Use of Google Analytics</h6>
              <br />
              Kashly uses Google Analytics, a web analytics service provided by
              Google, Inc. Google Analytics uses first-party cookies to report
              on user interactions with our web-based services. These cookies
              store non-personally identifiable information. Google Analytics
              aggregates technical data (non personally identifiable data
              relating to your usage of our website) with data collected from
              other users. Kashly uses this information to identify the most
              popular areas of the Services and how to best present and
              customise them.
              <br />
              <br />
              For more information about how Google handles technical
              information, please visit the site, "How Google uses data when you
              use our partners’ sites or apps", (located
              at:www.google.com/policies/privacy/partners ). Under no
              circumstances will your email, password, any personal financial
              data be collected by or shared with Google Analytics.
            </li>
            <br />
            <li>
              <h6>Fiduciary Duties and Fairness of Charges</h6>
              <br />
              In providing you its Services, Kashly will:
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>Put your best interests first;</li>
                <li>
                  Act with prudence, that is, with the skill, care, diligence
                  and good judgment of a professional;
                </li>
                <li>
                  Provide conspicuous, full and fair disclosure of all important
                  facts and not mislead you in any manner;
                </li>
                <li>Avoid conflicts of interest; and</li>
                <li>
                  Fully disclose and fairly manage, in your favour, any
                  unavoidable conflicts.
                </li>
              </ol>
              <br />
              Kashly will charge you fees that are transparent, uniform, fair
              and in accordance with the law. Kashly will not charge you more
              than once for the same service either directly or indirectly.
            </li>
            <br />

            <li>
              <h6>Limitation of Liability</h6>
              <br />
              You will not dispute and/or hold Kashly responsible for:
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Any act that is not an obligation of Kashly in this Agreement.
                </li>
                <li>
                  Any disclosures made by Kashly to any statutory body under any
                  law.
                </li>
                <li>
                  Any loss, notional or otherwise, incurred because of:
                  <br />
                  <ol style={{ listStyleType: "lower-roman" }}>
                    <li>
                      Delays either at the bank, bank payment systems, Registrar
                      and Transfer Agency or any Manufacturer (as defined under
                      Annexure 3 hereunder) or any other similar agencies or
                      entities;
                    </li>
                    <li>
                      Rejection of your instructions by the Bank, Registrar and
                      Transfer Agency or any Manufacturer;
                    </li>
                    <li>
                      Providing access to information and/or processing of
                      instructions authenticated by your Login Credentials;
                    </li>
                    <li>
                      Non-availability or non-accessibility of the website,
                      mobile application, electronic payment gateway,
                      telephone(s), or office(s) of Kashly for reasons including
                      those beyond Kashly’s control;
                    </li>
                    <li>
                      Any penalties, loss or damage to you which is due to the
                      negligence or actions of any Service Partner of Kashly and
                      not caused by any action of Kashly;
                    </li>
                    <li>Any act of Force Majeure (as defined hereunder);</li>
                    <li>
                      Any inaccuracy or typographical error on our website or
                      the mobile application or any written, E-mail & SMS
                      communication. Kashly specifically disclaims any liability
                      for such inaccuracies or errors;
                    </li>
                    <li>
                      Depletion of value of securities or Products (as defined
                      under Annexure 3) as a result of fluctuation in value of
                      the securities / Products or on account of non-performance
                      or underperformance of the securities or Products or any
                      other market conditions; or
                    </li>
                    <li>
                      Any act, omission or delay attributed to you or any
                      Service Partners resulting in Kashly not being able to
                      provide the Services to you.
                    </li>
                  </ol>
                </li>
              </ol>
              <br />
              You further acknowledge and agree that, to the maximum extent
              permitted by law, neither Kashly nor any of its Service Partners
              or Vendors will be liable for any incidental, special, exemplary
              or consequential damages, including loss of profits, loss of data
              or loss of goodwill, service interruption or in connection with
              the terms of this Agreement, as a result of your use of Services.
               
            </li>
            <br />

            <li>
              <h6>Your Content in Our Services</h6>
              <br />
              Some of our Services allow you to upload, submit, store, send or
              receive content. When you upload, submit, store, publish, send or
              receive content to or through our Services, you provide us with
              your consent to utilise such content, including for our marketing
              purposes, subject to our Privacy Policy. Such content must not:
              <br />
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  be harmful, harassing, blasphemous, defamatory, obscene,
                  pornographic, paedophilic, libellous, invasive of another’s
                  privacy, hateful or racially or ethnically objectionable,
                  disparaging, and relating to, or encouraging money laundering
                  or gambling.
                </li>
                <li>harm minors in any way.</li>
                <li>
                  infringe any patent, trademark, copyright or other
                  intellectual property rights.
                </li>
                <li>
                  deceive or mislead the reader about the origin of such
                  messages or communicate any information that is grossly
                  offensive or menacing in nature;
                </li>
                <li>
                  contain software viruses or any other programs designed to
                  interrupt, destroy or limit the functionality of another
                  computer or mobile device.
                </li>
                <li>
                  threaten the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order.
                </li>
                <li>
                  cause the incitement to commit any offence or prevent the
                  investigation of any offence.
                </li>
                <li>be insulting to any other nation.</li>
                <li>impersonate another person.</li>
                <li>
                  violate the provisions of the Information Technology Act, 2000
                  and rules and regulations thereunder or any other applicable
                  laws.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <h6>Modification and Termination of Services</h6>
              <br />
              We are constantly changing and improving our Services. We may add
              or remove functionalities or features, and / or we may stop a
              Service altogether, either due to a management decision or
              regulatory restrictions. In each case, we will give you reasonable
              advance notice. In case you wish to discontinue using the Services
              of Kashly, you may terminate your subscription in accordance with
              the termination provisions under the applicable Subscription
              Agreement. Even upon termination of the Subscription Agreement,
              the terms of this Agreement, to the extent applicable, shall
              continue to remain in force.
            </li>
            <br />

            <li>
              <h6>Indemnity</h6>
              <br />
              You agree to indemnify, defend, and hold Kashly, its Service
              Partners and its Vendors harmless from and against any and all
              losses, including legal fees, arising out of or relating to your
              failure to comply with the provisions of this Agreement.
            </li>
            <br />
            <li>
              <h6>Limitation of Remedies</h6>
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  In no event will Kashly, its Service Partners, its Vendors or
                  their respective officers, directors, or employees be liable
                  for any action performed or omitted to be performed or for any
                  errors of judgment in relation to your account.
                </li>
                <li>
                  Kashly shall not be held accountable for any losses incurred
                  as a result of any actions / occurrences as set out in Clause
                  X (Limitation of Liability) of this Agreement or such actions
                  / occurrences not attributable to Kashly.
                </li>
                <li>
                  A gesture of goodwill on behalf of Kashly such as refunds and
                  / or reimbursement of transaction charges or any other sum,
                  shall not constitute an acknowledgment of any wrongful act or
                  liability by Kashly and you shall not have any further or
                  additional recourse, legal or otherwise against Kashly.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <h6>General Provisions</h6>
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Force Majeure: The parties hereby agree that the period of
                  time during which Kashly is prevented or delayed in the
                  performance or fulfilling any obligation hereunder, due to
                  unavoidable delays caused by an event of Force Majeure shall
                  be added to Kashly’s time for performance thereof; and Kashly
                  shall have no liability by reason thereof. In such an event
                  Kashly agrees and endeavours to fulfil its obligations under
                  this agreement as expeditiously as possible upon ceasing of
                  such event of Force Majeure. A “Force Majeure” event for the
                  purposes of this Agreement shall include an act of God, flood,
                  earthquake, fire, explosion, strike, war, civil commotion,
                  insurrection, embargo, riots, political disturbances,
                  lockouts, epidemics, pandemics, any act, law, regulation or
                  order of the government, systemic electrical,
                  telecommunications, network or other utility failures
                  affecting Kashly to render services, including failure on
                  account of a cyber threat / risk.
                </li>
                <li>
                  Severability: If any provision of this Agreement is held by a
                  court of competent jurisdiction, or arbitration panel, as
                  applicable, to be unenforceable under applicable law, then
                  such provision shall be excluded from this Agreement and the
                  remainder of this Agreement shall be interpreted as if such
                  provision were so excluded and shall be enforceable in
                  accordance with its terms; provided however that, in such
                  event this agreement shall be interpreted so as to give
                  effect, to the greatest extent consistent with and permitted
                  by applicable law, to the meaning and intention of the
                  excluded provision as determined by such court of competent
                  jurisdiction or arbitration panel, as applicable.
                </li>
                <li>
                  Further Assurance: Each of the parties shall perform such
                  further acts and execute such further documents as may
                  reasonably be necessary to carry out and give full effect to
                  the provisions of this Agreement and the intentions of the
                  parties as reflected hereby.
                </li>
                <li>
                  Assignment: Neither You or Kashly may assign or otherwise
                  transfer the rights under this Agreement without written
                  consent of the other.
                </li>
              </ol>
            </li>
          </ol>
          <br />
        </p>
      </Container>
      <Footer />
    </>
  );
}

export default Terms;
