import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container, Breadcrumb, Button } from "react-bootstrap";
import API from "../api";
import { getAMCLogo } from "../services/Utilities";

/* code splitting for performance */
import loadable from '@loadable/component'
const Header = loadable(() => import('../components/Header'))
const Footer = loadable(() => import('../components/Footer'))
const NFOCard = loadable(() => import('../components/NFOCard'))

function Nfo() {
  const { id } = useParams();
  const [showNFODetails, setShowNFODetails] = useState(false);
  const [loading, setLoading] = useState({
    isActiveNFOLoading: false,
    isClosedNFOLoading: false,
  });

  const [activeNFO, setActiveNFO] = useState([
    {
      id: 0,
      fund: "",
      schemeName: "",
      objective: "",
      schemeType: "",
      schemeCategory: "",
      launchDate: "",
      earliestClosureDate: "",
      closureDate: "",
      loadDetails: "",
      minSubscription: "",
      website: "",
      createdAt: "",
      modifiedAt: "",
    },
  ]);

  const [closedNFO, setClosedNFO] = useState([
    {
      id: 0,
      fund: "",
      schemeName: "",
      objective: "",
      schemeType: "",
      schemeCategory: "",
      launchDate: "",
      earliestClosureDate: "",
      closureDate: "",
      loadDetails: "",
      minSubscription: "",
      website: "",
      createdAt: "",
      modifiedAt: "",
    },
  ]);

  const [nfoEntry, setNFOEntry] = useState({
    id: 0,
    fund: "",
    schemeName: "",
    objective: "Not available",
    schemeType: "Not available",
    schemeCategory: "Not available",
    launchDate: "N/A",
    earliestClosureDate: "",
    closureDate: "N/A",
    loadDetails: "Not available",
    minSubscription: "N/A",
    website: "",
    createdAt: "",
    modifiedAt: "",
  });

  const activeNfos = () => {
    setLoading({
      ...loading,
      isActiveNFOLoading: true,
    });
    API.getActiveNfos()
      .then((result) => {
        setLoading({
          ...loading,
          isActiveNFOLoading: false,
        });
        if (result.data.status === "success") {
          if (result.data.data) {
            setActiveNFO(result.data.data);
          }
          if (id) {
            result.data.data.forEach((nfo) => {
              if (nfo.id == id) {
                setNFOEntry(nfo);
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading({
          ...loading,
          isActiveNFOLoading: false,
        });
      });
  };

  const recentlyClosedNfos = () => {
    setLoading({
      ...loading,
      isClosedNFOLoading: true,
    });
    API.getRecentlyClosedNfos(10)
      .then((result) => {
        setLoading({
          ...loading,
          isClosedNFOLoading: false,
        });
        if (result.data.status === "success") {
          setClosedNFO(result.data.data);
          if (id) {
            result.data.data.forEach((nfo) => {

              if (nfo.id == id) {
                setNFOEntry(nfo);
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading({
          ...loading,
          isClosedNFOLoading: false,
        });
      });
  };

  const showActiveNFOs = () => {
    if (!showNFODetails) {
      return (
        <Container>
          <h5>Currently Open({activeNFO ? activeNFO.length : 0})</h5>
          <br />
          <Row>
            {activeNFO
              ? activeNFO.map((nfo) => {
                  return (
                    <Col lg={6}>
                      <NFOCard
                        id={nfo.id}
                        scheme={nfo.schemeName}
                        startDate={nfo.launchDate}
                        endDate={nfo.closureDate}
                        status="open"
                      />
                    </Col>
                  );
                })
              : null}
          </Row>
        </Container>
      );
    } else {
      return null;
    }
  };

  const showRecentlyClosedNFOs = () => {
    if (!showNFODetails) {
      return (
        <Container className="content-area">
          <h5>Recently Closed({closedNFO ? closedNFO.length : 0})</h5>
          <br />
          <Row>
            {closedNFO
              ? closedNFO.map((nfo) => {
                  return (
                    <Col lg={6}>
                      <NFOCard
                        id={nfo.id}
                        scheme={nfo.schemeName}
                        startDate={nfo.launchDate}
                        endDate={nfo.closureDate}
                        status="closed"
                      />
                    </Col>
                  );
                })
              : null}
          </Row>
        </Container>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (id) {
      setShowNFODetails(true);
    } else {
      setShowNFODetails(false);
    }
    activeNfos();
    recentlyClosedNfos();
  }, []);

  const showNFOEntryDetails = () => {
    if (
      showNFODetails &&
      !loading.isActiveNFOLoading &&
      !loading.isClosedNFOLoading
    ) {

      console.log(nfoEntry)
      console.log(id)
      return (
        <Container>
          <Row>
            <Col lg={8}>
              <div className="nfo-card">
                <Row>
                  <Col xs={2} lg={1}>
                    <img alt="amc-logo" src={getAMCLogo(nfoEntry.schemeName)} />
                  </Col>
                  <Col xs={10} lg={11}>
                    <h6>{nfoEntry.schemeName}</h6>
                    <span className="text-s">
                      <b>Subscription</b> {nfoEntry.launchDate} ~{" "}
                      {nfoEntry.closureDate}
                    </span>
                  </Col>
                </Row>
              </div>
              <br />
              <Row>
                <Col>
                  <h6>Fund Type & Category</h6>
                  {nfoEntry.schemeType} &#8226; {nfoEntry.schemeCategory}
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <h6>Fund Objective</h6>
                  {nfoEntry.objective}
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <h6>Load Details</h6>
                  {nfoEntry.loadDetails}
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <h6>Minimum Subscription</h6>
                  {nfoEntry.minSubscription}
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <div className="invest-nfo-cta">
                <h6>Interested in NFOs? </h6>
                <img
                  src="/assets/images/kashly-investments.svg"
                  alt="kashly-investments"
                />
                <p>
                  Are you looking for new opportunities available in the market?
                  Start your account with Kashly
                </p>
                <Button href="/open-account">Invest Now</Button>
              </div>
            </Col>
          </Row>
          <br />
          <br />
        </Container>
      );
    }
  };

  return (
    <>
      <Header page="nfo" />
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/nfo">NFO</Breadcrumb.Item>
          {id && <Breadcrumb.Item>{nfoEntry.schemeName}</Breadcrumb.Item>}
        </Breadcrumb>
      </Container>
      <br />
      <Container className="nfo-content-area">
        <Row>
          <Col>
            {!showNFODetails ? (
              <>
                <h2>New Fund Offerings</h2>
                <br />
                <p>
                  An NFO or New Fund Offer is when a mutual fund house (AMC)
                  launches a new mutual fund scheme. Just like stock market
                  Initial Public Offerings (IPOs), it is with the help of NFOs
                  that the fund houses raise the initial capital for purchasing
                  securities that are in line with the fund objective. <br />
                  <br />
                  The NFO is open for a specified duration, and it is during
                  this period that investors could invest in the scheme at the
                  offer price. In India, the NFO price in mutual funds is
                  generally fixed at Rs. 10 per unit of the mutual fund scheme.
                  Once the NFO period expires, existing or new investors can
                  only purchase units of the scheme at a specified price, which
                  is generally higher than the NFO price.
                </p>
                <br />
              </>
            ) : null}
          </Col>
          {loading.isActiveNFOLoading ? null : showActiveNFOs()}
          {loading.isClosedNFOLoading ? null : showRecentlyClosedNFOs()}
          {showNFOEntryDetails()}
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default Nfo;
