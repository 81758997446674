const SectionHeader = ({ heading, subheading, step }) => {
  return (
    <div className="section-header">
      {step ? <h6>STEP {step}/5</h6> : null}
      <h3>{heading}</h3>
      <p>{subheading}</p>
    </div>
  );
};
export default SectionHeader;
