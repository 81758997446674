import "../../theme/styles.scss";
import { Container, Row, Col, Button } from "react-bootstrap";

/* code splitting for performance */
import loadable from '@loadable/component'
const Header = loadable(() => import('../../components/Header'))
const Footer = loadable(() => import('../../components/Footer'))

function NRIAccount() {
  const kycDocs = [
    "Latest Photograph",
    "Attested PAN Card Copy",
    "Passport Copy",
    "Proof of Residence (Outside India)",
    "Bank Statement (NRI account)",
  ];
  const kycDocList = kycDocs.map((kycDoc) => {
    return (
      <li>
        <i class="bi bi-patch-check-fill"></i>&nbsp;&nbsp;{kycDoc}
      </li>
    );
  });

  return (
    <>
      <Header page="nriaccount" />
      <Container className="disclosure-section content">
        <Row>
          <Col>
            <h2>NRI Account Opening</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Most of the NRI's can invest in mutual funds in India. However,
              NRIs from USA/Canada has some restrictions or compliance
              considerations if they are looking to invest in India.NRI mutual
              funds are regulated by the foreign Exchange Management Act of
              1999. As per the act, NRI’s are allowed to make investments into
              capital market which include stocks, Exchange Traded Funds and
              Mutual Funds.
            </p>
            <Button variant="outline-primary" href="https://blog.kashly.in/can-nri-invest-in-mutual-funds/">Read More</Button>
          </Col>
        </Row>
      </Container>
      <div className="featured-container-grey content">
        <Container>
          <Row>
            <Col lg={6}>
              <h4>Procedures for NRIs in India</h4>
              <br />
              <p>
                AMCs cannot accept Mutual Funds in foreign currencies therefore
                to invest in Mutual Funds of India, one has to open an NRO/NRE
                or Foreign Currency Non-resident (FCNR) account with an Indian
                bank.
                <br />
                <br />
                Investments can be made under same person or another way you can
                invest is by having someone else invest on your behalf and make
                investment decisions who is known as POA holder. However,
                signatures of both NRI investor and POA holder is mandatory on
                KYC documents.
              </p>
              <br />
              <Button href="/open-account">Create Account</Button>
            </Col>
            <Col lg={1}>
              <br />
            </Col>
            <Col lg={5}>
              <h4>Required KYC Documents</h4>
              <div className="checked-icon-list">
                <ul>{kycDocList}</ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="nri-section content">
        <Row>
          <Col lg={4} xs={12}>
            <div className="outline-box">
              <h4>NRE Account</h4>
              <p>
                This is Non-Resident External (NRE) account which can be in the
                form of savings, current, fixed or Recurring deposit. You need
                to deposit the foreign currency in this account. <br />
                <br />
                To be able to deposit Indian currency, you must open NRO
                account. There is no upper limit on the transaction amount in an
                NRE account.
              </p>
            </div>
          </Col>
          <Col lg={4} xs={12}>
            <div className="outline-box">
              <h4>NRO Account</h4>
              <p>
                The NRO or Non-Resident Ordinary account is in the form of
                savings or current account that is meant for NRIs to manage
                their income earned in India.
                <br />
                <br />
                In NRO account, foreign currency gets converted into Indian
                rupees after it gets deposited.
              </p>
            </div>
          </Col>
          <Col lg={4} xs={12}>
            <div className="outline-box">
              <h4>FCNR Account</h4>
              <p>
                This stands for Foreign Currency Non-Repatriable account
                deposits. In this account, NRIs can remit their Earnings in one
                of the 6 currencies such as USD,GBP,JPY,EUR,AUD & CAD.
                <br />
                <br />
                Funds can be transferred from other FCNR or NRE accounts. In
                FCNR, the principal and interest don't accrue any tax.
              </p>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <h2>US/Canada Investors</h2>
            <p>
              Mutual fund Investments are little complicate for USA and Canadian
              Residents due to the compliance requirements under Foreign Account
              Tax Compliance Act (FATCA). Under FATCA, its compulsory for all
              Mutual funds to share details of all transactions of NRI with US
              and Canadian Government. FATCA ensures that there is no deliberate
              Tax evasion on the income generated by US Citizens overseas.
            </p>
            <p>
              India signed the Inter-Government Agreement (IGA) for improving
              International Tax Compliance and Implementing FATCA. Due to this
              reason, mutual fund houses stopped taking investments for USA and
              Canada. However after consultation between the fund houses and
              experts, many have again started the investments from USA and
              Canada with some conditions. They can make investments only
              through offline transaction with additional declaration signed by
              client for open ended funds from SBI, ICICI, TATA, Aditya Birla
              Sun Life, Franklin Templeton and PPFAS Mutual Fund.
            </p>
            <p>
              In short NRI’s can Invest in his/her home country. Though there
              are initial hassles, in a long run return of Investment can be
              worth it. So, there is certainly no reason for you to be left out
              of investing in one of the fastest-growing economies.
            </p>
            <Button href="/open-account">Create Account</Button>
          </Col>
          
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default NRIAccount;
