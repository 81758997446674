import { Row, Col } from "react-bootstrap";

export const CalculatorList = () => {
  return (
    <div className="tool-list">
      <Row>
        <Col>
          <h4>Calculators</h4>
          <a href="/sip-calculator">SIP Calculator</a>
          <br />
          <a href="/swp-calculator">SWP Calculator</a>
          <br />
          <a href="/goal-calculator">Goal Calculator</a>
          <br />
          <a href="/inflation-calculator">Inflation Calculator</a>
        </Col>
      </Row>
    </div>
  );
};
