export const PRIMARY = "PR";
export const SECONDARY = "SE";
export const THIRD = "TH";
export const GUARDIAN = "GU";

export const GET_START = "1";
export const HOLDING_FORM = "2";
export const BANK_FORM = "3";
export const NOMINEE_FORM = "4";
export const DOCUMENT_FORM = "5";
export const SUBMIT_FORM = "6";

export const DOC_PAN = "pan"
export const DOC_BANK_STATEMENT = "bank_statement"
export const DOC_KYC_FORM = "kyc_document"
export const DOC_AADHAAR = "aadhaar"
export const DOC_VISA = "visa"
export const DOC_PASSPORT = "passport"
export const DOC_PASSPORT_SIZE_PHOTO = "passport_photo"


export const SIGNUP_URL = "https://kashly.in/open-account";
export const CALENDLY_LINK = "https://calendly.com/kashlyniveth/introduction-meeting" 
export const WHATSAPP_CHANNEL = "https://whatsapp.com/channel/0029VaC5euv5vKA685ycsQ1M"