import "../theme/styles.scss";
import { Row, Col, Container } from "react-bootstrap";

/* code splitting for performance */
import loadable from "@loadable/component";
const Header = loadable(() => import("../components/Header"));
const Footer = loadable(() => import("../components/Footer"));

function Contact() {
  return (
    <>
      <Header page="contact" />
      <Container className="contact-section">
        <h1 className="text-center">Contact us</h1>
        <br />
        <br />
        <Row>
          <Col xs={12} md={6} lg={3}>
            <h6>Account Opening</h6>
            <p>
              10.00AM to 05.00PM IST
              <br />
              Monday - Friday
              <br />
              <br />
              +91 95620 50863
              <br />
              +91 93532 34979
              <br />
            </p>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <h6>Operations & Support</h6>
            <p>
              10.00AM to 06.00PM IST
              <br />
              Monday - Friday
              <br />
              <br />
              +91 95620 50863
              <br />
              +91 93532 34979
              <br />
            </p>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <h6>Technical Support</h6>
            <p>
              10.00AM to 06.00PM IST
              <br />
              Monday - Friday
              <br />
              <br />
              +91 93532 34979
              <br />
              +91 73388 31788
              <br />
            </p>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <h6>Email Support</h6>
            <p>
              10.00AM to 10.00PM IST
              <br />
              All Days
              <br />
              <br />
              support@kashly.in
              <br />
            </p>
          </Col>
        </Row>
        <p>
          <br />
          *Our existing cutomers can directly reach out to their relationship
          manager.
        </p>
      </Container>
      <Container className="contact-section">
        <Row>
          <Col xs={12} md={5}>
            <h4>We are based in Kerala</h4>Kashly (Capsquare Technologies
            Private Limited) is an AMFI registered distributor of Mutual Funds
            (ARN-186781)
            <br />
            <br />
          </Col>
          <Col xs={12} md={1} />
          <Col xs={12} md={6}>
            <h6>HQ</h6>Capsquare Technologies Pvt Ltd
            <br />
            Door No 4/461, Second Floor
            <br />
            Valamkottil Towers, Judgemukku
            <br />
            Thrikkakara P.O, Kakkanad, Kochi, Kerala
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default Contact;
