import { useState, useEffect } from "react";
import { Header, Footer, Range } from "../../components";
import { CalculatorList } from "./Components";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { formatAmount } from "../../services/Utilities";
import {
  swpCalculator,
  validateSWPCalculator,
} from "../../services/CalculatorServices";

function SWPCalculator() {
  const [swpCalculatorForm, setSwpCalculatorForm] = useState({
    investment: 1000000,
    withdrawal: 10000,
    duration: 10,
    rate: 8,
    totalWithdrawal: 0,
    futureValue: 0,
  });

  const handleFieldChange = (e) => {
    setSwpCalculatorForm({
      ...swpCalculatorForm,
      [e.target.name]: e.target.value,
    });

  };

  const swpChartData = {
    datasets: [
      {
        labels: ["Earnings", "Total Investment"],
        data: [
          Math.round(swpCalculatorForm.totalWithdrawal),
          Math.round(swpCalculatorForm.investment),
        ],
        backgroundColor: ["#59aa00", "#0080ff"],
      },
    ],
  };

  function calculateSWPPlan() {
    let error = validateSWPCalculator(swpCalculatorForm);
    if (error === "") {
      let swpPlan = swpCalculator(
        swpCalculatorForm.investment,
        swpCalculatorForm.withdrawal,
        swpCalculatorForm.rate,
        swpCalculatorForm.duration
      );

      setSwpCalculatorForm({
        ...swpCalculatorForm,
        futureValue: Math.round(swpPlan.futureValue),
        totalWithdrawal: Math.round(swpPlan.totalWithdrawal),
      });
    }
  }

  useEffect(() => {
    let swpPlan = swpCalculator(
      swpCalculatorForm.investment,
      swpCalculatorForm.withdrawal,
      swpCalculatorForm.rate,
      swpCalculatorForm.duration
    );

    setSwpCalculatorForm({
      ...swpCalculatorForm,
      futureValue: Math.round(swpPlan.futureValue),
      totalWithdrawal: Math.round(swpPlan.totalWithdrawal),
    });
  }, []);

  /* calculate the latest value when there is a change in amount,duration or rate */
  useEffect(() => {
    calculateSWPPlan();
  }, [
    swpCalculatorForm.investment,
    swpCalculatorForm.withdrawal,
    swpCalculatorForm.duration,
    swpCalculatorForm.rate,
  ]);

  return (
    <>
      <Header page="swpcalculator" />
      <Container className="content">
        <h2>SWP Calculator</h2>
        <p>
          SWP Calculator shows the total value of the mutual fund investment after the withdrawal.
        </p>
      </Container>

      <Container className="calculators">
        <Row>
          <Col lg={9}>
            <div className="tool-container">
              <Row>
                <Col>
                  <Form
                    onSubmit={(e) => {
                      calculateSWPPlan();
                    }}
                  >
                    <Range
                      min={1000}
                      max={1000000000}
                      step={1000}
                      current={swpCalculatorForm.investment}
                      name="investment"
                      label="Total Investment"
                      handler={(e) => handleFieldChange(e)}
                    />
                    <Range
                      min={100}
                      max={100000}
                      step={100}
                      current={swpCalculatorForm.withdrawal}
                      name="withdrawal"
                      label="Withdrawal (per month)"
                      handler={(e) => handleFieldChange(e)}
                    />
                    <Range
                      min={1}
                      max={30}
                      step={1}
                      current={swpCalculatorForm.rate}
                      name="rate"
                      label="Expected Return (Anually %)"
                      handler={(e) => handleFieldChange(e)}
                    />
                    <Range
                      min={1}
                      max={30}
                      step={1}
                      current={swpCalculatorForm.duration}
                      name="duration"
                      label="Duration (Years)"
                      handler={(e) => handleFieldChange(e)}
                    />
                  </Form>
                </Col>
                <Col>
                  <Doughnut
                    type="doughnut"
                    data={swpChartData}
                    options={{ maintainAspectRatio: true }}
                  />
                  <br />
                  <h5 className="text-center">
                    Future Value: {formatAmount(swpCalculatorForm.futureValue)}
                  </h5>

                  <h6 className="text-center">
                    <font color="#59aa00">Total Withdrawal : </font>
                    {formatAmount(swpCalculatorForm.totalWithdrawal)}
                  </h6>
                  <h6 className="text-center">
                    <font color="#0080ff">Total Investment : </font>
                    {formatAmount(swpCalculatorForm.investment)}
                  </h6>
                  <br />
                  <div className="text-center">
                    <Button href="/open-account">Invest Now</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={3}>
            <CalculatorList />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
export default SWPCalculator;
