import { Col } from "react-bootstrap";
const ContentPane = ({ children }) => {
  const style = {
    borderRadius: "5px",
  };
  return (
    <Col lg={8} xs={12}>
      <div style={style}>{children}</div>
    </Col>
  );
};
export default ContentPane;
