import "../../theme/styles.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container, Button, Alert, Form } from "react-bootstrap";
import isEmail from "validator/lib/isEmail";
import API from "../../api";

/* code splitting for performance */
import loadable from "@loadable/component";
const Header = loadable(() => import("../../components/Header"));
const Footer = loadable(() => import("../../components/Footer"));

function OpenAccount() {
  const navigate = useNavigate();
  const [accountForm, setAccountForm] = useState({
    email: "",
    error: false,
    message: "",
  });

  const benefits = [
    "Free account & no transaction charges",
    "We service regular mutual funds",
    "Expert guidance by professionals",
    "All investments under one platform",
    "Periodic monitoring and engagement",
    "Yearly review and rebalance support",
  ];
  const benefitList = benefits.map((benefit) => {
    return (
      <li>
        <i class="bi bi-patch-check-fill"></i>&nbsp;&nbsp;{benefit}
      </li>
    );
  });

  const createAccount = (event) => {
    event.preventDefault();
    let error = validateForm();
    if (error === "") {
      API.getApplication(accountForm.email)
        .then((result) => {
          if (result.status == 200) {
            isApplicationVerified(result.data)
              ? navigate("/application/" + result.data.data.email)
              : setAccountForm({
                  ...accountForm,
                  error: false,
                  message:
                    "Please verify your email address to continue. Check your inbox for verification email.",
                });
          }
          if (result.status == 204) {
            let data = { email: accountForm.email };
            API.createApplication(data)
              .then((result) => {
                if (result.status == 200) {
                  setAccountForm({
                    ...accountForm,
                    error: false,
                    message:
                      "We've sent you an email for verification. Please check your inbox.",
                  });
                }
              })
              .catch((error) => {
                handleError(
                  "Something went wrong! please contact Kashly support."
                );
              });
          }
        })
        .catch((error) => {
          handleError("Something went wrong! please contact Kashly support.");
        });
    }
  };

  const handleError = (message) => {
    setAccountForm({
      ...accountForm,
      error: true,
      message: message,
    });
  };

  const isApplicationVerified = (response) => {
    return response.data.isVerified == "Y" ? true : false;
  };

  function validateForm() {
    let error = "";
    if (!isEmail(accountForm.email)) {
      error = "Email is invalid";
    }
    return error;
  }

  function showMessage() {
    if (accountForm.error) {
      return <Alert variant="danger">{accountForm.message}</Alert>;
    } else {
      if (accountForm.message === "") {
        return null;
      } else {
        return <Alert variant="success">{accountForm.message}</Alert>;
      }
    }
  }

  return (
    <>
      <Header page="signup" />
      <div className="featured-container-grey">
        <Container className="content">
          <Row>
            <Col id="dematless-account" xs={12} md={6}>
              <>
                <h1>Start invest in mutual funds!</h1>
                <br />
                <p>
                  You only need a bank account &amp; PAN card to start mutual
                  fund investment with us. Are you a NRI Investor ?{" "}
                  <a href="/nri-account">Start Here</a>
                </p>
                <div className="checked-icon-list">
                  <ul>{benefitList}</ul>
                </div>
              </>
            </Col>
            <Col xs={12} md={1} />
            <Col id="signup-section" xs={12} md={5}>
              <div className="kashly-card">
                <h4>Create Kashly Account</h4>
                <p className="secondary-text text-s">
                  Or continue your existing application.
                </p>
                <Form
                  onSubmit={(event) => {
                    createAccount(event);
                  }}
                >
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={accountForm.email}
                      onChange={(e) => {
                        setAccountForm({
                          ...accountForm,
                          email: e.target.value,
                        });
                      }}
                    />
                    <br />
                    {showMessage()}
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Continue
                  </Button>
                  <p className="secondary-text text-xs">
                    <br />
                    You will be opening an investment account with
                    Kashly(Capsquare Technologies Private Limited), an AMFI
                    registered mutual fund distributor.
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <div className="process-section">
          <Row>
            <Col>
              <br />
              <br />
              <h1>How It Works ?</h1>
              <p>
                Kashly is a mutual fund distribution platform. No fees, no
                hidden charges for investors. We earn a small fee from the
                mutual fund companies based on your investments. The commissions
                details are on our{" "}
                <a href="/disclosure" target="_blank">
                  disclosure
                </a>{" "}
                page, and you can see that it does not influence our fund
                recommendations.
              </p>
              <br />
            </Col>
          </Row>
          <Row>
            <Col className="step-content" xs={12} md={6}>
              <h6>STEP 1</h6>
              <h3>Create Your Account</h3>
              <br />
              <p>
                Create a demat-less mutual fund investment account with Kashly
                from anywhere in the world. If you are a first-time investor,
                complete your KYC in a few simple steps.
              </p>
            </Col>
            <Col className="step-image" xs={12} md={6}>
              <img
                src="/assets/images/kashly-signup.svg"
                alt="kashly-account-opening"
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="process-section">
          <Row>
            <Col className="step-image d-none d-md-block" xs={12} md={6}>
              <img
                src="/assets/images/kashly-mutual-fund-advisor.svg"
                alt="kashly-mutual-fund-advisor"
              />
            </Col>
            <Col className="step-content" xs={12} md={6}>
              <h6>STEP 2</h6>
              <h3>Discuss With Experts</h3>
              <br />
              <p>
                Discuss your goals and risk appetite with our experts which will
                help them to suggest you the right mutual funds.
              </p>
            </Col>
            <Col className="step-image d-md-none" xs={12} md={6}>
              <img
                src="/assets/images/kashly-mutual-fund-advisor.svg"
                alt="kashly-advisor"
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="process-section">
          <Row>
            <Col className="step-content" xs={12} md={6}>
              <h6>STEP 3</h6>
              <h3>Start Investing!</h3>
              <br />
              <p>
                Kashly has made the entire investment process simple and
                hassle-free. You can invest in as many mutual funds with your
                Kashly account.
              </p>
            </Col>
            <Col className="step-image" xs={12} md={6}>
              <img
                src="/assets/images/kashly-investments.svg"
                alt="kashly-investments"
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="process-section">
          <Row>
            <Col className="step-image d-none d-md-block" xs={12} md={6}>
              <img
                src="/assets/images/kashly-review-rebalance.svg"
                alt="kashly-muatul-fund-review"
              />
            </Col>
            <Col className="step-content" xs={12} md={6}>
              <h6>STEP 4</h6>
              <h3>Track, Review and Rebalance</h3>
              <br />
              <p>
                Kashly will assist you to review your mutual funds on a periodic
                basis and do the necessary rebalancing of your portfolio.
              </p>
            </Col>
            <Col className="step-image d-md-none" xs={12} md={6}>
              <img
                src="/assets/images/kashly-review-rebalance.svg"
                alt="kashly-mutual-fund-review"
              />
            </Col>
          </Row>
        </div>
      </Container>

      <Footer />
    </>
  );
}
export default OpenAccount;
