import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAMCLogo } from "../../services/Utilities";

import "../../theme/styles.scss";
import "./Funds.scss";

export const Filter = ({ type, label, filter, setFilter }) => {
  const handleFilterClick = (e) => {
    if (type === "category") {
      setFilter({
        ...filter,
        category: "",
        subCategory: "",
      });
    }
    if (type === "subcategory") {
      setFilter({
        ...filter,
        subCategory: "",
      });
    }
  };
  return (
    <div className="filter">
      <span className="text-s">{label}</span>
      <span className="icon">
        <i id={type} onClick={handleFilterClick} className="bi bi-x-circle"></i>
      </span>
    </div>
  );
};

export const FilterBar = ({ filter, setFilter }) => {
  let filters = [];

  if (filter.category !== "") {
    filters.push(
      <Filter
        filter={filter}
        setFilter={setFilter}
        type="category"
        label={filter.category}
      />
    );
  }
  if (filter.subCategory !== "") {
    filters.push(
      <Filter
        filter={filter}
        setFilter={setFilter}
        type="subcategory"
        label={filter.subCategory}
      />
    );
  }
  return <div>{filters}</div>;
};

export const FundList = ({ funds, filter }) => {
  let _funds = [];
  if (typeof funds != "undefined") {
    if (filter.subCategory === "") {
      funds.map((fund, key) => {
        if (fund.category === filter.category) {
          _funds.push(<FundItem key={key} fund={fund} />);
        }
      });
    } else {
      funds.map((fund, key) => {
        if (
          fund.category === filter.category &&
          fund.sub_category === filter.subCategory
        ) {
          _funds.push(<FundItem key={key} fund={fund} />);
        }
      });
    }
  }
  return _funds;
};

export const FundItem = ({ fund }) => {
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate("/funds/" + id);
  };

  return (
    <div id="12121" className="fund-item" onClick={() => handleClick(fund.id)}>
      <Row>
        <Col xs={2} lg={1}>
          <img
            alt={fund.name}
            style={{ width: "40px", height: "40px" }}
            src={getAMCLogo(fund.name)}
          />
        </Col>
        <Col style={{ paddingLeft: "30px" }}>
          <h6>
            {fund.name.length > 75
              ? fund.name.substr(0, fund.name.indexOf("-"))
              : fund.name}
          </h6>
          <Row>
            <Col className="text-xs">
              {fund.category} &#9679; {fund.sub_category} &#9679; NAV
              &nbsp;&#8377;{Math.round(fund.nav * 100) / 100}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const CategoryList = ({ categories, filter, setFilter }) => {
  const handleSelect = (e) => {
    setFilter({
      ...filter,
      category: e.target.id,
      subCategory: "",
    });
  };
  return (
    <div style={{ margin: "2rem auto" }}>
      <h6>Categories</h6>
      <ul className="category-ul">
        {categories.map((category, key) => {
          return (
            <li
              id={category}
              key={key}
              className="category-li"
              onClick={handleSelect}
            >
              {category}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const SubCategoryList = ({ subCategories, filter, setFilter }) => {
  const handleSelect = (e) => {
    setFilter({
      ...filter,
      subCategory: e.target.id,
    });
  };

  return (
    <div style={{ margin: "2rem auto" }}>
      <h6>Sub Categories</h6>
      <ul className="category-ul">
        {subCategories.map((subCategory, key) => {
          return (
            <li
              key={key}
              id={subCategory}
              className="category-li"
              onClick={handleSelect}
            >
              {subCategory}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const FundCategory = ({ label, filter, setFilter }) => {
  const handleClick = (e) => {
    switch (e.target.innerHTML) {
      case "Equity Funds":
        setFilter({
          ...filter,
          category: "Equity Scheme",
          subCategory: "",
        });
        break;
      case "Debt Funds":
        setFilter({
          ...filter,
          category: "Debt Scheme",
          subCategory: "",
        });
        break;
      case "Hybrid Funds":
        setFilter({
          ...filter,
          category: "Hybrid Scheme",
          subCategory: "",
        });
        break;
      case "Liquid Funds":
        setFilter({
          ...filter,
          category: "Debt Scheme",
          subCategory: "Liquid Fund",
        });
        break;

      case "ELSS/Tax Saver Funds":
        setFilter({
          ...filter,
          category: "Equity Scheme",
          subCategory: "ELSS",
        });
        break;
      case "Foreign Fund of Funds":
        setFilter({
          ...filter,
          category: "Other Scheme",
          subCategory: "FoF Overseas",
        });
        break;
      case "Solution/Theme Oriented Funds":
        setFilter({
          ...filter,
          category: "Solution Oriented Scheme",
          subCategory: "",
        });
        break;
      case "Index Funds":
        setFilter({
          ...filter,
          category: "Other Scheme",
          subCategory: "Index Funds",
        });
        break;
    }
  };
  return (
    <div onClick={handleClick} className="category">
      {label}
    </div>
  );
};

export const ExploreSection = ({ filter, setFilter }) => {
  return (
    <>
      <div className="explore-container">
        <h4>Get Started</h4>
        <p>Explore the right mutual funds by choosing the category.</p>
        <FundCategory
          filter={filter}
          setFilter={setFilter}
          label="Equity Funds"
        />
        <FundCategory
          filter={filter}
          setFilter={setFilter}
          label="Debt Funds"
        />
        <FundCategory
          filter={filter}
          setFilter={setFilter}
          label="Hybrid Funds"
        />
        <br />
        <FundCategory
          filter={filter}
          setFilter={setFilter}
          label="Liquid Funds"
        />
        <FundCategory
          filter={filter}
          setFilter={setFilter}
          label="Foreign Fund of Funds"
        />
        <br />
        <FundCategory
          filter={filter}
          setFilter={setFilter}
          label="ELSS/Tax Saver Funds"
        />
        <FundCategory
          filter={filter}
          setFilter={setFilter}
          label="Solution/Theme Oriented Funds"
        />
      </div>
    </>
  );
};
