import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Alert, Spinner } from "react-bootstrap";
import Field from "./Field";
import SectionHeader from "./SectionHeader";
import API from "../../api";
import { mapStateToAPI, isFileRequired } from "./utils";
import { validateDocuments } from "../validations";
import {
  DOC_PAN,
  DOC_VISA,
  DOC_BANK_STATEMENT,
  DOC_KYC_FORM,
  DOC_AADHAAR,
  DOC_PASSPORT,
  DOC_PASSPORT_SIZE_PHOTO,
  NOMINEE_FORM,
  SUBMIT_FORM,
} from "../constants";

const Document = ({ application, setApplication }) => {
  const [errorMessage, setErrorMesage] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAccepted, setIsAccepted] = useState({
    isAgreed: false,
    isDeclared: false,
  });
  const [files, setFiles] = useState([
    {
      name: DOC_PAN,
      title: "Upload self attested copy of your PAN card",
      file: null,
      isRequired: false,
      isUploaded: false,
      isLoading: false,
    },
    {
      name: DOC_BANK_STATEMENT,
      title: "Upload your last 3 months bank statement",
      file: null,
      isRequired: false,
      isUploaded: false,
      isLoading: false,
    },
    {
      name: DOC_KYC_FORM,
      title: "Upload scanned copy of CKYC form with signature",
      file: null,
      isRequired: false,
      isUploaded: false,
      isLoading: false,
    },
    {
      name: DOC_AADHAAR,
      title: "Upload your recent e-Aadhaar downloaded from UIDAI website",
      file: null,
      isRequired: false,
      isUploaded: false,
      isLoading: false,
    },
    {
      name: DOC_VISA,
      title: "Upload latest visa / visa page in passport",
      file: null,
      isRequired: false,
      isUploaded: false,
      isLoading: false,
    },
    {
      name: DOC_PASSPORT,
      title: "Upload latest copy of passport both front and back page",
      file: null,
      isRequired: false,
      isUploaded: false,
      isLoading: false,
    },
    {
      name: DOC_PASSPORT_SIZE_PHOTO,
      title: "Upload your latest passport size photo",
      file: null,
      isRequired: false,
      isUploaded: false,
      isLoading: false,
    },
  ]);

  useEffect(() => {
    let taxStatus = application.holderDetails.taxStatus;
    let kycCompliance = application.kycCompliance;
    let documentStatus = application.documentsUploadStatus;
    let newFiles = [...files];
    files.map((file, index) => {
      if (isFileRequired(file.name, taxStatus, kycCompliance, documentStatus)) {
        newFiles[index].isRequired = true;
      }
    });
    setFiles(newFiles);
    getUserIPAddress();
  }, [application]);

  const showErrorMessage = () => {
    return errorMessage !== "" ? (
      <Alert variant="danger">{errorMessage}</Alert>
    ) : null;
  };

  const handleContinue = () => {
    let error = validateDocuments(files, isAccepted);
    if (error == "") {
      let newState = {
        ...application,
        form: SUBMIT_FORM,
        ipAddress: ipAddress,
      };
      modifyApplication(newState);
    } else {
      setErrorMesage(error);
    }
  };

  const handleFieldChange = (event) => {
    setIsAccepted({
      ...isAccepted,
      [event.target.name]: event.target.checked,
    });
  };

  const modifyApplication = (state) => {
    setIsSubmitted(true);
    API.updateApplication(application.id, mapStateToAPI(state))
      .then((result) => {
        setApplication(state);
      })
      .catch((error) => {
        setIsSubmitted(false);
        setErrorMesage("Something went wrong! Please try again");
      });
  };

  const handleBack = () => {
    let newState = {
      ...application,
      form: NOMINEE_FORM,
    };
    setApplication(newState);
  };

  const handleUploadState = (doc, docName, loadStatus, uploadStatus) => {
    const newFiles = files.map((file) => {
      if (file.name === docName) {
        return {
          ...file,
          file: doc,
          isLoading: loadStatus,
          isUploaded: uploadStatus,
        };
      }
      return file;
    });
    setFiles(newFiles);
  };

  const getUserIPAddress = () => {
    API.getUserIP()
      .then((result) => {
        setIPAddress(result.data.ip);
      })
      .catch((error) => {});
  };

  const handleFileUpload = (event) => {
    const doc = event.target.files[0];
    const docName = event.target.name;
    if (isValidFile(doc)) {
      const formData = new FormData();
      formData.append(docName, doc);
      setErrorMesage("");
      handleUploadState(doc, docName, true, false);
      API.uploadApplicationFile(application.id, formData)
        .then(() => {
          handleUploadState(doc, docName, false, true);
        })
        .catch((error) => {
          handleUploadState(doc, docName, false, false);
          if (error.response) {
            if (error.response.status === 400) {
              setErrorMesage(
                "File format not supported. Please upload files only in JPEG/PNG/PDF format"
              );
            }
          }
        });
    }
  };

  const isValidFile = (doc) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (doc.size > 5 * 1000 * 1024) {
      setErrorMesage(
        "File is too large to upload. The maximum allowed file size is 5MB"
      );
      return false;
    }
    if (!allowedTypes.includes(doc.type)) {
      setErrorMesage(
        "File format not supported. Please upload files only in JPEG/PNG/PDF format"
      );
      return false;
    }
    return true;
  };

  const showFiles = () => {
    let requiredDocs = [];
    files.map((file) => {
      if (file.isRequired) {
        requiredDocs.push(
          <Col lg="4" md="6" sm="6">
            <FileUploader
              name={file.name}
              title={file.title}
              isUploaded={file.isUploaded}
              isLoading={file.isLoading}
            />
          </Col>
        );
      }
    });
    return requiredDocs;
  };

  const FileUploader = ({ name, title, isUploaded, isLoading }) => {
    let innerHTML = <></>;
    if (isLoading) {
      innerHTML = <Spinner animation="border" variant="primary" />;
    } else {
      if (isUploaded) {
        innerHTML = <i class="bi bi-file-earmark-check-fill" />;
      } else {
        innerHTML = <i class="bi bi-file-earmark-arrow-up" />;
      }
    }
    return (
      <div className="document-container">
        <Row>
          <Col xs="2">{innerHTML}</Col>
          <Col xs="10">{title}</Col>
        </Row>
        <input id={name} name={name} type="file" onChange={handleFileUpload} />
      </div>
    );
  };

  return (
    <Container className="application-wrapper">
      <SectionHeader
        step="5"
        heading="Upload Documents"
        subheading="Please upload your latest documents for verification. The maximum allowed file size is 5MB per document."
      />
      <div className="section-header">
        <b>Know Your Customer (KYC)</b>
        <p>
          KYC is a customer identification process for account opening with any
          financial entity. KYC establishes an investor's identity & address
          through relevant supporting documents. First time investors candownload the CKYC form <a href="/assets/files/CKYC-CAMSKRA-D15022024-FORM.pdf" target="_blank">here</a>
        </p>
      </div>
      <Row>{showFiles()}</Row>
      <div className="section-header">
        <b>Declaration</b>
        <p>
          Please provide your declaration and accept all our{" "}
          <a href="https://kashly.in/terms" target="_blank">
            terms and conditions
          </a>{" "}
          on this section.
        </p>
      </div>
      <Row>
        <Col>
          <Field
            type="checkbox"
            name="isDeclared"
            label={
              "I hereby declare that the information provided on this application is true and correct to my knowledge."
            }
            value={isAccepted.isDeclared}
            onChange={(e) => handleFieldChange(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            type="checkbox"
            name="isAgreed"
            label={
              "I accept and agree to all the terms and conditions for opening an investment account with Kashly (Capsquare Technologies Private Limited), an AMFI registered mutual fund distributor."
            }
            value={isAccepted.isAgreed}
            onChange={(e) => handleFieldChange(e)}
          />
        </Col>
      </Row>
      {showErrorMessage()}
      <Row>
        <Col>
          <Button onClick={handleBack}>Back</Button>
          <Button onClick={handleContinue}>
            {isSubmitted ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;Submitting...
              </>
            ) : (
              <>Submit</>
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export default Document;
