export const currencyFormat = (amount) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
  });
  return formatter.format(amount);
};

export const formatAmount = (amount) => {
  var unit = "";
  var CRORE = 10000000;
  var LAC = 100000;
  var unittrans1 = "Cr";
  var unittrans2 = "Lac";

  if (parseFloat(amount) > CRORE) {
    amount = amount / CRORE;
    unit = unittrans1;
  } else if (amount > LAC) {
    amount = amount / LAC;
    unit = unittrans2;
  }
  return (
    amount.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }) +
    " " +
    unit
  );
};

/*
Format date from YYYY-MM-DD to DD-MM-YYYY
*/
export const formatDate = (date) => {
  return date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0, 2);
};

export const pnlClass = (number) => {
  if (number >= 0) {
    return "profit";
  } else {
    return "loss";
  }
};

export const getAMCLogo = (fundName) => {
  fundName = fundName.toUpperCase();
  if (fundName.substr(0, 2) === "JM") {
    return "/assets/images/amc/jm.png";
  } else if (fundName.substr(0, 2) === "NJ") {
    return "/assets/images/amc/nj.png";
  } else if (fundName.substr(0, 3) === "LIC") {
    return "/assets/images/amc/lic.png";
  } else if (fundName.substr(0, 3) === "BOI") {
    return "/assets/images/amc/boi.png";
  } else if (fundName.substr(0, 3) === "ITI") {
    return "/assets/images/amc/iti.png";
  } else if (fundName.substr(0, 3) === "SBI") {
    return "/assets/images/amc/sbi.png";
  } else if (fundName.substr(0, 3) === "DSP") {
    return "/assets/images/amc/dsp.png";
  } else if (fundName.substr(0, 3) === "BNP") {
    return "/assets/images/amc/bnp.png";
  } else if (fundName.substr(0, 3) === "L&T") {
    return "/assets/images/amc/lt.png";
  } else if (fundName.substr(0, 3) === "UTI") {
    return "/assets/images/amc/uti.png";
  } else if (fundName.substr(0, 4) === "DHFL") {
    return "/assets/images/amc/dhfl.png";
  } else if (fundName.substr(0, 4) === "IIFL") {
    return "/assets/images/amc/iifl.png";
  } else if (fundName.substr(0, 4) === "HSBC") {
    return "/assets/images/amc/hsbc.png";
  } else if (fundName.substr(0, 4) === "HDFC") {
    return "/assets/images/amc/hdfc.png";
  } else if (fundName.substr(0, 4) === "IDFC") {
    return "/assets/images/amc/idfc.png";
  } else if (fundName.substr(0, 4) === "IDBI") {
    return "/assets/images/amc/idbi.png";
  } else if (fundName.substr(0, 4) === "PGIM") {
    return "/assets/images/amc/pgim.png";
  } else if (fundName.substr(0, 4) === "NAVI") {
    return "/assets/images/amc/navi.png";
  } else if (fundName.substr(0, 4) === "TATA") {
    return "/assets/images/amc/tata.png";
  } else if (fundName.substr(0, 4) === "AXIS") {
    return "/assets/images/amc/axis.png";
  } else if (fundName.substr(0, 5) === "ICICI") {
    return "/assets/images/amc/icici.png";
  } else if (fundName.substr(0, 5) === "KOTAK") {
    return "/assets/images/amc/kotak.png";
  } else if (fundName.substr(0, 5) === "MIRAE") {
    return "/assets/images/amc/mirae.png";
  } else if (fundName.substr(0, 5) === "PPFAS") {
    return "/assets/images/amc/ppfas.png";
  } else if (fundName.substr(0, 5) === "PARAG") {
    return "/assets/images/amc/ppfas.png";
  } else if (fundName.substr(0, 5) === "UNION") {
    return "/assets/images/amc/union.png";
  } else if (fundName.substr(0, 5) === "WHITE") {
    return "/assets/images/amc/whiteoak.png";
  } else if (fundName.substr(0, 7) === "QUANTUM") {
    return "/assets/images/amc/quantum.png";
  } else if (fundName.substr(0, 5) === "QUANT") {
    return "/assets/images/amc/quant.png";
  } else if (fundName.substr(0, 6) === "ADITYA") {
    return "/assets/images/amc/birla.png";
  } else if (fundName.substr(0, 6) === "NIPPON") {
    return "/assets/images/amc/nippon.png";
  } else if (fundName.substr(0, 6) === "CANARA") {
    return "/assets/images/amc/canara.png";
  } else if (fundName.substr(0, 6) === "BARODA") {
    return "/assets/images/amc/baroda.png";
  } else if (fundName.substr(0, 6) === "TAURUS") {
    return "/assets/images/amc/taurus.png";
  } else if (fundName.substr(0, 7) === "INVESCO") {
    return "/assets/images/amc/invesco.png";
  } else if (fundName.substr(0, 7) === "MOTILAL") {
    return "/assets/images/amc/motilal.png";
  } else if (fundName.substr(0, 7) === "SHRIRAM") {
    return "/assets/images/amc/shriram.png";
  } else if (fundName.substr(0, 8) === "FRANKLIN") {
    return "/assets/images/amc/franklin.png";
  } else if (fundName.substr(0, 8) === "SUNDARAM") {
    return "/assets/images/amc/sundaram.png";
  } else if (fundName.substr(0, 8) === "MAHINDRA") {
    return "/assets/images/amc/mahindra.png";
  } else if (fundName.substr(0, 9) === "PRINCIPAL") {
    return "/assets/images/amc/principal.png";
  } else if (fundName.substr(0, 9) === "EDELWEISS") {
    return "/assets/images/amc/edelweiss.png";
  } else if (fundName.substr(0, 10) === "INDIABULLS") {
    return "/assets/images/amc/indiabulls.png";
  } else return "/assets/images/amc/kashly.png";
};