import { Container, Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <>
      <Container className="application-loader center-content">
      <Spinner animation="border" variant="primary" />
        <p>Hold on! We're loading your application</p>
      </Container>
    </>
  );
};
export default Loader;
