function Section({ variant, children }) {
  let background = "";
  switch (variant) {
    case "grey":
      background = "#f1f1f1";
      break;
    default:
      background = "#ffffff";
  }
  const style = {
    width: "100%",
    overflow: "hidden",
    padding:"5rem 0",
    background: background,
  };
  return <div style={style}>{children}</div>;
}
export default Section;
