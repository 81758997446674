import { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";

import { Header, Footer, Range } from "../../components";
import { CalculatorList } from "./Components";
import { Doughnut } from "react-chartjs-2";
import { formatAmount } from "../../services/Utilities";

import {
  sipCalculator,
  getSIPPeriod,
  validateSIPCalculator,
} from "../../services/CalculatorServices";

function SIPCalculator() {
  const [sipCalculatorForm, setSipCalculatorForm] = useState({
    sipAmount: 5000,
    sipFrequency: "monthly",
    period: 0,
    duration: 15,
    rate: 12,
    totalInvestment: 0,
    futureValue: 0,
    earnings: 0,
  });

  const handleAmount = (e) => {
    setSipCalculatorForm({
      ...sipCalculatorForm,
      sipAmount: e.target.value,
    });
  };

  const handleDuration = (e) => {
    setSipCalculatorForm({
      ...sipCalculatorForm,
      duration: e.target.value,
    });
  };

  const handleRate = (e) => {
    setSipCalculatorForm({
      ...sipCalculatorForm,
      rate: e.target.value,
    });
  };

  const sipChartData = {
    datasets: [
      {
        labels: ["Earnings", "Total Investment"],
        data: [
          Math.round(sipCalculatorForm.earnings),
          Math.round(sipCalculatorForm.totalInvestment),
        ],
        backgroundColor: ["#59aa00", "#0080ff"],
      },
    ],
  };

  function calculateSIPRetruns() {
    //event.preventDefault();
    let error = validateSIPCalculator(sipCalculatorForm);
    if (error === "") {
      let annualInflationRate = 0;
      let period = getSIPPeriod(sipCalculatorForm.sipFrequency);
      let sipResult = sipCalculator(
        sipCalculatorForm.sipAmount,
        period,
        sipCalculatorForm.rate - annualInflationRate,
        sipCalculatorForm.duration
      );

      setSipCalculatorForm({
        ...sipCalculatorForm,
        futureValue: Math.round(sipResult.futureValue),
        totalInvestment: Math.round(sipResult.investment),
        earnings: Math.round(sipResult.earnings),
      });
    }
  }

  useEffect(() => {
    let annualInflationRate = 0;
    let period = getSIPPeriod(sipCalculatorForm.sipFrequency);
    let sipResult = sipCalculator(
      sipCalculatorForm.sipAmount,
      period,
      sipCalculatorForm.rate - annualInflationRate,
      sipCalculatorForm.duration
    );

    setSipCalculatorForm({
      ...sipCalculatorForm,
      futureValue: Math.round(sipResult.futureValue),
      totalInvestment: Math.round(sipResult.investment),
      earnings: Math.round(sipResult.earnings),
    });
  }, []);

  /* calculate the latest value when there is a change in amount,duration or rate */
  useEffect(() => {
    calculateSIPRetruns();
  }, [
    sipCalculatorForm.sipAmount,
    sipCalculatorForm.duration,
    sipCalculatorForm.rate,
  ]);

  return (
    <>
      <Header page="sipcalculator" />
      <Container className="content">
        <h2>SIP Calculator</h2>
        <p>
          Find the future value of your monthly/quarterly SIP investments
          through mutual funds.
        </p>
      </Container>

      <Container className="calculators">
        <Row>
          <Col lg={9}>
            <div className="tool-container">
              <Row>
                <Col>
                  <Form
                    onSubmit={(event) => {
                      calculateSIPRetruns(event);
                    }}
                  >
                    <Form.Group controlId="sipFrequency">
                      <Form.Label>SIP Frequency</Form.Label>
                      <Form.Control
                        as="select"
                        value={sipCalculatorForm.sipFrequency}
                        onChange={(e) =>
                          setSipCalculatorForm({
                            ...sipCalculatorForm,
                            sipFrequency: e.target.value,
                          })
                        }
                      >
                        <option value="monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="half-yearly">Half-Yearly</option>
                        <option value="yearly">Yearly</option>
                      </Form.Control>
                    </Form.Group>
                    <Range
                      min={100}
                      max={100000}
                      step={100}
                      current={sipCalculatorForm.sipAmount}
                      label="SIP Amount"
                      handler={handleAmount}
                    />
                    <Range
                      min={1}
                      max={30}
                      step={1}
                      current={sipCalculatorForm.duration}
                      label="SIP Duration (Years)"
                      handler={handleDuration}
                    />
                    <Range
                      min={1}
                      max={30}
                      step={1}
                      current={sipCalculatorForm.rate}
                      label="Expected Return (Anually %)"
                      handler={handleRate}
                    />
                  </Form>
                </Col>
                <Col>
                  <Doughnut
                    type="doughnut"
                    data={sipChartData}
                    options={{ maintainAspectRatio: true }}
                  />
                  <br />
                  <h5 className="text-center">
                    Future Value: {formatAmount(sipCalculatorForm.futureValue)}
                  </h5>
                  <h6 className="text-center">
                    <font color="#59aa00">Earnings : </font>
                    {formatAmount(sipCalculatorForm.earnings)}
                  </h6>
                  <h6 className="text-center">
                    <font color="#0080ff">Total Investment : </font>
                    {formatAmount(sipCalculatorForm.totalInvestment)}
                  </h6>
                  <br />
                  <div className="text-center">
                    <Button href="/open-account">Invest Now</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={3}>
            <CalculatorList />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
export default SIPCalculator;
