import validator from "validator";
import { hasDuplicates } from "../services/Utilities";
import {
  getHolderRole,
  isSingleHolding,
  isMinorDOB,
} from "./Application/utils";
import { PRIMARY, SECONDARY } from "./constants";

export const validateStarterForm = (data) => {
  let errorMessage = ``;
  if (data.type === "")
    errorMessage = `Please choose the appropriate holding account mode`;
  else if (data.taxStatus === "")
    errorMessage = `Please select the tax status `;
  return errorMessage;
};

export const validateHolderForm = (holders, holdingType) => {
  let errorMessage = ``;
  let panNumbers = [];
  let holderTypes = [];
  let holderNames = [];
  const reversedHolders = [...holders].reverse();

  /* field validations */
  reversedHolders.map((holder) => {
    let role = getHolderRole(holder.type).toLowerCase();
    panNumbers.push(holder.pan);
    holderTypes.push(holder.type);
    holderNames.push(holder.name);

    if (holder.name == "")
      errorMessage = `Please fill the name of ${role} as per PAN card`;
    else if (holder.name.length < 3)
      errorMessage = `Invalid full name for ${role}`;
    else if (!validatePersonName(holder.name))
      errorMessage = `Invalid full name for ${role}`;
    else if (holder.pan === "")
      errorMessage = `Please fill the PAN card number for ${role}`;
    else if (!validatePAN(holder.pan))
      errorMessage = `PAN card number is invalid for ${role}`;
    else if (holder.phone === "")
      errorMessage = `Contact number of ${role} can not be empty`;
    else if (!validator.isNumeric(holder.phone))
      errorMessage = `Contact number of ${role} is invalid`;
    else if (holder.phone.length !== 10)
      errorMessage = `Contact number of ${role} is incorrect`;
    else if (holder.email === "")
      errorMessage = `Email address can not be empty for ${role}`;
    else if (!validator.isEmail(holder.email))
      errorMessage = `Email address is invalid for ${role}`;
    else if (!validateDOB(holder.dob))
      errorMessage = `Date of birth is invalid for ${role}`;
    else if (holder.birthCity === "")
      errorMessage = `City of birth can not be empty for ${role}`;
    else if (holder.birthCountry === "")
      errorMessage = `Please select country of birth for ${role}`;
    else if (holder.citizenship === "")
      errorMessage = `Please select citizenship for ${role}`;
    else if (holder.nationality === "")
      errorMessage = `Please select nationality for ${role}`;
    else if (holder.pepStatus === "")
      errorMessage = `Please select PEP status for ${role}`;
    else if (holder.occupation === "")
      errorMessage = `Please select occupation for ${role}`;
    else if (holder.income === "")
      errorMessage = `Please select gross annual income for ${role}`;
    else if (holder.taxResidency === "")
      errorMessage = `Please select tax residency status for ${role}`;

    if (holder.taxResidency === "Y") {
      if (holder.taxCountry === "")
        errorMessage = `Please select the tax country for ${role}`;
      else if (holder.identityType === "")
        errorMessage = `Please select the identity type for ${role}`;
      else if (holder.taxReference === "")
        errorMessage = `Please enter the tax reference number for ${role}`;
    }
  });

  /* business validations
  if (!isSingleHolding(holdingType) && errorMessage === "") {
    if (!holderTypes.includes(PRIMARY) || !holderTypes.includes(SECONDARY)) {
      errorMessage = `For Joint / Anyone Survivor accounts, second holder is mandatory`;
    }
  }
  if (hasDuplicates(panNumbers) && errorMessage === "") {
    errorMessage =
      "Duplicate PAN card! each holder should provide their valid PAN numbers";
  }
  if (hasDuplicates(holderNames) && errorMessage === "") {
    errorMessage =
      "Duplicate holders! application holders can not be the same. Is that a typing mistake?";
  }
  */

  return errorMessage;
};

export const validateBankAccount = (accounts) => {
  let errorMessage = "";
  accounts.map((account) => {
    if (account.type === "") errorMessage = "Please select the account type";
    else if (account.accountNumber === "")
      errorMessage = "Please enter the account number";
    else if (!validator.isNumeric(account.accountNumber))
      errorMessage = "Account number is invalid";
    else if (account.accountNumber.length <= 6)
      errorMessage = "Account number is invalid";
    else if (!vaidateIFSC(account.ifsc)) errorMessage = "IFSC code is invalid";
    else if (account.micr === "") errorMessage = "Please enter the MICR code";
    else if (!validator.isNumeric(account.micr))
      errorMessage = "MICR code is invalid";
    else if (account.micr.length != 9) errorMessage = "MICR code is invalid";
  });

  return errorMessage;
};

export const validateNominee = (nominees) => {
  let errorMessage = ``;
  nominees.map((nominee, index) => {
    let key = index + 1;
    if (nominee.nomineeName === "")
      errorMessage = `Please enter the nominee ${key} name`;
    else if (nominee.relation === "")
      errorMessage = `Please select the nominee ${key} relation`;
    else if (!validateDOB(nominee.dob))
      errorMessage = `Date of birth for nominee ${key} is invalid`;
    else if (nominee.allocation === "")
      errorMessage = `Please provide allocation percentage for nominee ${key}`;
    else if (nominee.allocation > 100 || nominee.allocation < 1)
      errorMessage = `Allocation for nominee ${key} is incorrect, please enter a value between 1 ~ 100`;
    else if (isMinorDOB(nominee.dob)) {
      if (nominee.guardianName === "") {
        errorMessage = `Please enter the guardian ${key} name`;
      } else if (nominee.guardianRelation === "")
        errorMessage = `Please select the guardian ${key} relation`;
      else if (!validateDOB(nominee.guardianDOB)) {
        errorMessage = `Date of birth for guardian ${key} is invalid`;
      } else if (!validateGuardianDOB(nominee.guardianDOB)) {
        errorMessage = `Date of birth for guardian ${key} is invalid`;
      }
    }
  });
  /* if all errors are cleared, check for sum of allocation*/
  if (errorMessage == "") errorMessage = validateAllocationSum(nominees);

  return errorMessage;
};

export const validateAllocationSum = (nominees) => {
  let allocationSum = 0;
  nominees.map((nominee) => {
    if (nominee.allocation)
      allocationSum = allocationSum + Number(nominee.allocation);
  });
  if (allocationSum == 100) return "";
  else return "Total of all nominee allocation should be 100%";
};

export const validateDocuments = (files, isAccepted) => {
  let errorMessage = ``;
  files.toReversed().map((file) => {
    if (file.isRequired && !file.isUploaded) {
      errorMessage = file.title;
    }
  });
  if (errorMessage == "") {
    if (!isAccepted.isDeclared)
      errorMessage = `Please accept the declaration to submit the application`;
    else if (!isAccepted.isAgreed)
      errorMessage = `Please accept the terms and conditions to submit the application`;
  }
  return errorMessage;
};

export const validatePAN = (panNumber) => {
  let regex = new RegExp("[A-Z]{5}[0-9]{4}[A-Z]{1}");
  return regex.test(panNumber.toUpperCase());
};

export function validateDOB(dob) {
  let currentYear = new Date().getFullYear();
  let currentDate = new Date().toJSON().slice(0, 10);
  let dobYear = parseInt(dob.substr(0, 4));
  if (dob === "") return false;
  else if (!validator.isDate(dob)) return false;
  else if (dobYear > currentYear) return false;
  else if (dobYear < currentYear - 100) return false;
  else if (dob >= currentDate) return false;
  else return true;
}

export function validateGuardianDOB(dob) {
  let currentYear = new Date().getFullYear();
  let dobYear = parseInt(dob.substr(0, 4));
  if (Math.abs(currentYear - dobYear) > 18) return true;
  else return false;
}

export function vaidateIFSC(ifscCode) {
  let regex = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$");
  return regex.test(ifscCode.toUpperCase());
}

export function validatePersonName(name) {
  let regex = new RegExp("^s*([A-Za-z]{1,}([.,] |[-']| ))+[A-Za-z]+.?s*$");
  return regex.test(name);
}

export const isEmail = (email) => {
  if (email === "") return false;
  else if (!validator.isEmail(email)) return false;
  else return true;
};
