import "../theme/styles.scss";
import { Container } from "react-bootstrap";

/* code splitting for performance */
import loadable from "@loadable/component";
const Header = loadable(() => import("../components/Header"));
const Footer = loadable(() => import("../components/Footer"));

function Privacy() {
  return (
    <>
      <Header page="privacy" />
      <Container>
        <br />
        <br />
        <h4>Kashly Privacy Policy</h4>
        <p>
          Date of last revision: <b>October 24, 2024</b>
          <br />
        </p>
        <br />
        <p style={{ fontSize: "14px" }}>
          <b>Capsquare Technologies Private Limited</b>, a private limited
          company having its registered office at Door No 4/461, Second Floor,
          Valamkottil Towers, Judgemukku, Thrikkakara P.O, Kakkanad, Kochi,
          Kerala its affiliates and group entities, together referred to as
          “Company”, “We”, “Us” or “Our” which owns and operates the website{" "}
          <a href="https://kashly.in" target="_blank" rel="noreferrer">
            www.kashly.in
          </a>{" "}
          and/or the mobile application <b>'Kashly'</b> (collectively referred
          to as the <b>“Platform”</b>) is committed to protecting Your privacy
          and the information that You share while using the Platform. We value
          the trust You place in Us. That’s why We maintain the highest security
          standards for securing the transactions and Your information.
          <br />
          <br />
          This Privacy Policy specifies the manner in which personal data and
          other information are collected, received, stored, processed,
          disclosed, transferred, dealt with or otherwise handled by the
          Company. This Privacy Policy does not apply to information that You
          provide to, or that is collected by, any third-party (excluding the
          Company or its affiliates set out in paragraph 3 below) through the
          Platform, and any Third-Party Sites that You access or use in
          connection with the services offered on the Platform.
          <br />
          <br />
          Please read the Privacy Policy carefully prior to using or registering
          on the Platform or accessing/availing the services on the Platform
          including interalia in relation to mutual fund distribution and
          KRA/KYC verification (<b>“Services”</b>). By visiting the Platform,
          You (<b>“You” or “Your”</b>), accept and agree to be bound by the
          terms and conditions of this privacy policy (<b>“Privacy Policy”</b>).
          This Privacy Policy is incorporated into and subject to the{" "}
          <a href="https://kashly.in/terms" target="_blank" rel="noreferrer">
            terms of use
          </a>{" "}
          of the Platform (<b>“Terms”</b>) and shall be read harmoniously and in
          conjunction with the Terms.
        </p>

        <br />
        <p style={{ fontSize: "14px" }}>
          <ol style={{ listStyleType: "upper-roman" }}>
            <li>
              <h6>Collection of Information</h6>
              <p>
                We collect Personal Information (defined below) from You when
                You register or set up an account with Us on the Platform. You
                can browse certain sections of the Platform without being a
                registered member, however, to avail certain Services on the
                Platform (such as investing in funds) You are required to
                register with Us. This Privacy Policy applies to information (
                <b>“Personal Information”</b>) that:
              </p>
              <ul>
                <li>
                  You may provide to Us voluntarily while registering on Our
                  Platform for using Our Services, such as mobile number, email
                  address, password, date of birth, gender, Permanent Account
                  Number (PAN), signature, marital status, nominee details, We
                  may also ask You for certain financial information, including
                  Your billing address, bank account details, credit card
                  number, expiration date and/or other payment related details
                  or other payment method data, and debit instructions or other
                  standing instructions to process payments for the Services. We
                  may ask You to provide certain additional information about
                  Yourself on a case to case basis. All information disclosed by
                  You shall be deemed to be disclosed willingly and without any
                  coercion. No liability pertaining to the authenticity/
                  genuineness/misrepresentation/fraud/negligence, etc. of the
                  information disclosed shall lie on the Company nor will the
                  Company be in any way responsible to verify any information
                  obtained from You;
                </li>
                <li>
                  We may retrieve from Your records available with third party
                  including from Know Your Customer (KYC) Registration Agency
                  (KRA) such as name, KYC details, KYC status, father’s name,
                  occupation, address details and related documents.;
                </li>
                <li>
                  Further, if You choose to invest through the Platform, We will
                  also collect information about Your transactions including
                  transaction status and details and Your investment behaviour;
                  and
                </li>
                <li>
                  When and if You download and/or use the Platform through Your
                  mobile, We may receive information about Your location, Your
                  IP address, and/or Your mobile device, including a unique
                  identifier number for Your device. We may use this information
                  to provide You with location-based Services including but not
                  limited to search results and other personalized content. You
                  can withdraw Your consent at any time by disabling the
                  location-tracking functions on Your mobile. However, this may
                  affect Your enjoyment of certain functionalities on Our
                  Platform.
                </li>
              </ul>
              <br />
              <p>
                All transactional information including financial information
                gathered by the Company shall be stored on servers, log files
                and any other storage system owned by the Company or by third
                parties in India.
                <br />
                <br />
                Our primary goal in doing so is to provide You a safe,
                efficient, smooth and customized experience on the Platform. The
                information collected allows Us to provide the Services and
                features on the Platform that most likely meet Your needs, and
                to customize the Platform to make Your experience safer and
                easier. More importantly, while doing so, We collect the above –
                mentioned Personal Information from You that We consider
                necessary for achieving this purpose.
                <br />
                <br />
                We may also collect certain non-personal information, such as
                Your internet protocol address, web request, operating system,
                browser type, URL, internet service provider, IP address,
                aggregate user data, browser type, software and hardware
                attributes, pages You request, and cookie information, etc.
                which will not identify with You specifically (
                <b>“Non – Personal Information”</b>), while You browse, access
                or use the Platform. We receive and store Non – Personal
                Information, by the use of data collection devices such as
                “cookies” on certain pages of the Platform, in order to help and
                analyze Our web – page flow, track user trends, measure
                promotional effectiveness, and promote trust and safety. We
                offer certain additional features on the Platform that are only
                available through the use of a “cookie”. We place both permanent
                and temporary cookies in Your computer’s hard drive.
                <br />
                <br />
                We also use cookies to allow You to enter Your password less
                frequently during a session on the Platform. Most cookies are
                “session cookies,” meaning that they are automatically deleted
                from Your hard drive at the end of a session. You are always
                free to decline Our cookies if Your browser permits, although in
                that case, You may not be able to use certain features or
                Services being provided on the Platform or You may be required
                to re-enter Your password each time you log – in or access the
                Platform during a session.
                <br />
                <br />
                In general, You can browse the Platform without telling Us who
                You are or revealing any Personal Information about Yourself. In
                such a case, we will only collect and store the Non – Personal
                Information. Once You give us your Personal Information, You are
                not anonymous to Us. Wherever possible, while providing the
                information to Us, We indicate which fields are mandatory and
                which fields are optional for You. You always have the option to
                not provide the Personal Information to Us through the Platform
                by choosing to not use a particular Service or feature being
                provided by Us on the Platform, which requires You to provide
                such information. We may automatically track certain information
                about You based upon Your behaviour on the Platform. We use this
                information to do internal research on Your demographics,
                interests, and behaviour to better understand, protect and serve
                You. This information is compiled and analysed by the Company on
                an aggregated basis and not individually, in a manner that does
                not specifically identify You.
                <br />
                <br />
                If You choose to post messages on our message boards, chat rooms
                or other message areas or leave feedback, We will collect and
                store such information You provide to Us. We retain this
                information as necessary to resolve disputes, provide customer
                support, respond to queries and inquires, and troubleshoot
                problems and improve the Services.
                <br />
                <br />
                If You send us correspondence, such as emails or letters, or if
                other users or third parties send us correspondence about Your
                activities or postings on the Platform, We may collect and
                retain such information into a file specific to You for
                responding to Your request and addressing concerns in relation
                to Your use of the Platform. We shall be entitled to retain Your
                Personal Information and other information for such duration as
                may be required for the purposes specified hereunder and will be
                used by Us only in accordance with this Privacy Policy.
              </p>
            </li>
            <br />
            <li>
              <h6>Use of Your Information</h6>
              <br />
              <p>
                We use the Personal Information and other Non-Personal
                Information, for the following: (i) to provide and improve the
                Services on the Platform that You request; (ii) to resolve
                disputes and troubleshoot problems; (iii) to help promote a safe
                service on the Platform and protect the security and integrity
                of the Platform, the Services and the users; (iv) collect money
                from You in relation to the Services, (v) inform You about
                online and offline offers, products, services, and updates; (vi)
                customize Your experience on the Platform or share marketing
                material with You; (vii) to detect, prevent and protect Us from
                any errors, fraud and other criminal or prohibited activity on
                the Platform; (viii) enforce and inform about our terms and
                conditions; (ix) to process and fulfil Your request for Services
                or respond to Your comments, and queries on the Platform; (x) to
                contact You; (xi) to allow Our business partners and/or
                associates to present customised messages to You; (xii) to
                communicate important notices or changes in the Services
                provided by the Company, use of the Platform and the
                terms/policies which govern the relationship between You and the
                Company and with Our affiliates as set out in paragraph 3 below
                for providing services to You; and (xiii) for any other purpose
                after obtaining Your consent at the time of collection.
                <br />
                <br />
                You also specifically agree and consent to Us collecting,
                storing, processing, transferring and sharing information
                (including Personal Information) related to You with third
                parties such as Banks, financial institutions, credit
                information companies, entities registered under applicable laws
                with Securities Exchange Board of India, National Stock Exchange
                of India Limited/BSE Limited/Central Registry of Securitisation
                Asset Reconstruction and Security Interest of India
                (CERSAI)/payment gateways/ collecting banks/KRAs etc. solely for
                the purpose of reviewing your profile and processing your
                transaction requests for the Services or for such other
                products/services offered by us or our group, affiliate or
                associates.
                <br />
                <br />
                In addition to the above, We identify and use Your IP address to
                help diagnose problems with Our server, resolve such problems
                and administer the Platform. Your IP address is also used to
                help identify you and to gather broad demographic information.
                <br />
                <br />
                We may occasionally ask You to complete optional online surveys.
                These surveys may ask You for Your contact information and
                demographic information (like zip code, age, or income level).
                We use this information to tailor Your experience at the
                Platform, providing You with content that We think You might be
                interested in and to display content according to Your
                preferences. We use Your Personal Information to send You
                promotional emails, however, We will provide You the ability to
                opt-out of receiving such emails from Us. If You opt out, the
                Company may still send You non-promotional emails, such as
                emails about the Services and Your account on the Platform.
                <br />
                <br />
                Unless and until, You explicitly give Your consent to Us, to do
                so, We will not share Your Personal Information with another
                user of the Platform and vice versa.
              </p>
            </li>
            <br />
            <li>
              <h6>Sharing of information</h6>
              <br />
              <p>
                We may make Your Personal Information and/or other Non-Personal
                Information available to Our affiliates to enable them to
                provide Services through the Platform to You. Please note that
                all information shared with our affiliates or made available to
                our affiliates will be governed by the terms of this Privacy
                Policy. In addition to the Personal Information and in order to
                provide Services to You. We may also disclose Your Personal
                Information to third party vendors, consultants, and other
                service providers who work for the Company, who are bound by
                contractual obligations to keep such personal information
                confidential and use it only for the purposes for which We
                disclose it to them. This disclosure may be required for Us, for
                instance, to provide You access to Our Services and process
                payments including validation of Your bank accounts, to
                facilitate and assist Our marketing and advertising
                activities/initiatives, for undertaking auditing or data
                analysis, or to prevent, detect, mitigate, and investigate
                fraudulent or illegal activities related to Our Services. We do
                not disclose Your Personal Information to third parties for
                their marketing and advertising purposes without Your explicit
                consent.
                <br />
                <br />
                The Company may disclose Your information, to the extent
                necessary: (i) to comply with laws and to respond to lawful
                requests and legal process, (ii) to protect the rights and
                property of the Company, Our users, and others, including to
                enforce the Terms, and (iii) in an emergency to protect the
                personal safety and assets of the Company, the users, or any
                person. In such an event the Company is in no manner responsible
                for informing You or seeking Your approval or consent.
                <br />
                <br />
                We and Our affiliates may, in compliance with applicable laws,
                share/transfer/assign all of Your Personal Information and other
                information with any other business entit(ies), in the event of
                a merger, sale, re-organization, amalgamation, joint ventures,
                assignment, restructuring of business or transfer or disposition
                of all or any portion of Our business.
              </p>
            </li>
            <br />
            <li>
              <h6>Connecting your email account</h6>
              <br />
              <p>
                You may also choose to provide Your explicit consent to
                connect/integrate Your email account(s) with Your account on the
                Platform by clicking on the authentication email sent by the
                Company on Your registered email address. You will be expressly
                asked in each case whether You want to connect Your email
                address with Your account on the Platform. You can also choose
                to enable Our access to one or more of Your email accounts by
                connecting such account(s) with Your account on the Platform.
              </p>
            </li>
            <br />
            <li>
              <h6>
                Links to other third – party sites and collection of information
              </h6>
              <br />
              <p>
                Our Platform may link You to other third – party Platforms
                (“Third – Party Sites”) that may collect Your Personal
                Information including Your IP address, browser specification, or
                operating system. The Company is not in any manner responsible
                for the security of such information or their privacy practices
                or content of those Third – Party Sites. Additionally, You may
                also encounter “cookies” or other similar devices on certain
                pages of the Third – Party Sites and it is hereby clarified by
                the Company that the Platform does not control the use of
                cookies by these Third – Party Sites. These third-party service
                providers and Third-Party Sites may have their own privacy
                policies governing the storage and retention of Your information
                that You may be subject to. This Privacy Policy does not govern
                any information provided to, stored on, or used by these
                third-party providers and Third-Party Sites. We recommend that
                when You enter a Third-Party Site, You review the Third Party
                Site’s privacy policy as it relates to safeguarding of Your
                information. We use third-party advertising companies to serve
                ads when You visit the Platform. These companies may use
                information (not including Your name, address, email address, or
                telephone number) about Your visits to the Platform and
                Third-Party Sites in order to provide advertisements about goods
                and services of interest to You. You agree and acknowledge that
                We are not be liable for the information published in search
                results or by any Third-Party Sites.
              </p>
            </li>
            <br />
            <li>
              <h6>Security precautions and measures</h6>
              <br />
              <p>
                Our Platform has reasonable security measures and safeguards in
                place to protect Your privacy and Personal Information from
                loss, misuse, unauthorized access, disclosure, destruction, and
                alteration of the information in compliance with applicable
                laws. Further, whenever You change or access Your account on the
                Platform or any information relating to it, We offer the use of
                a secure server. We cannot however ensure or warrant the
                security of any information You transmit to the Company or
                guarantee that Your Personal Information and/or other
                Non-Personal Information provided for availing the Services or
                Platform may not be accessed, disclosed, altered or destroyed by
                a breach of any of Our security measures and safeguards. It is
                further clarified that You have and so long as You access and/or
                use the Platform (directly or indirectly) the obligation to
                ensure that You shall at all times, take adequate physical,
                managerial, and technical safeguards, at Your end, to preserve
                the integrity and security of Your data which shall include and
                not be limited to Your Personal Information.
                <br />
                <br />
                When payment information is being transmitted on or through the
                Platform, it will be protected by encryption technology. You
                expressly consent to the sharing of Your information with third
                party service providers, including payment gateways, to process
                payments and manage your payment-related information. Hence, the
                Company cannot guarantee that transmissions of Your
                payment-related information or Personal Information will always
                be secure or that unauthorized third parties will never be able
                to defeat the security measures taken by the Company or the
                Company’s third-party service providers. The Company assumes no
                liability or responsibility for disclosure of Your information
                due to errors in transmission, unauthorized third-party access,
                or other causes beyond its control. You play an important role
                in keeping Your personal information secure. You shall not share
                Your Personal Information or other security information for Your
                account with anyone. The Company has undertaken reasonable
                measures to protect Your rights of privacy with respect to Your
                usage of the Platform controlled by Us and Our Services.
                However, We shall not be liable for any unauthorized or unlawful
                disclosures of Your personal information made by any third
                parties who are not subject to Our control.
              </p>
            </li>
            <br />
            <li>
              <h6>Your Consent and Changes to Privacy Policy</h6>
              <br />
              <p>
                <b>Your Consent:</b> By visiting the Platform or setting
                up/creating an account on the Platform for availing the Services
                on the Platform at such time, You signify Your acceptance to the
                provisions of the Privacy Policy.
                <br />
                <br />
                <b>Withdrawal of Consent:</b> You may choose to withdraw Your
                consent provided hereunder at any point in time. Such withdrawal
                of consent must be sent in writing to support@kashly.in. In case
                You do not provide Your consent or later withdraw Your consent,
                We request you not to access the Platform and/or use the
                Services and also reserve the right to not provide You any
                Services through the Platform. In such a scenario, the Company
                may delete Your information (Personal or otherwise) or
                de-identify it so that it is anonymous and not attributable to
                You.
                <br />
                <br />
                <b>Changes to Privacy Policy:</b> We reserve the unconditional
                right to change, modify, add, or remove portions of this Privacy
                Policy at any time, without specifically notifying You of such
                changes. Any changes or updates will be effective immediately.
                You should review this Privacy Policy regularly for changes. You
                can determine if changes have been made by checking the “Last
                Updated” legend above. Your acceptance of the amended Privacy
                Policy shall signify Your consent to such changes and agreement
                to be legally bound by the same.
              </p>
            </li>
          </ol>
          <br />
        </p>
        <h6>Questions ?</h6>
        <p style={{ fontSize: "14px" }}>
          Please feel free to contact us at support@kashly.in regarding any
          questions on the Privacy Policy.
        </p>
        <br />
      </Container>
      <Footer />
    </>
  );
}

export default Privacy;
