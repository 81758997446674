import {
  PRIMARY,
  SECONDARY,
  THIRD,
  GUARDIAN,
  DOC_PAN,
  DOC_VISA,
  DOC_BANK_STATEMENT,
  DOC_KYC_FORM,
  DOC_AADHAAR,
  DOC_PASSPORT,
  DOC_PASSPORT_SIZE_PHOTO,
} from "../constants";

export const mapStateToAPI = (state) => {
  let holders = [];
  let accounts = [];
  let nominees = [];

  if (state.holderDetails.holderRecord) {
    state.holderDetails.holderRecord.map((holder) => {
      holders.push({
        name: holder.name,
        type: holder.type,
        dob: holder.dob,
        pan: holder.pan.toUpperCase(),
        email: holder.email,
        birthCity: holder.birthCity,
        birthCountry: holder.birthCountry,
        citizenship: holder.citizenship,
        identityType: holder.identityType,
        income: holder.income,
        nationality: holder.nationality,
        occupation: holder.occupation,
        pepStatus: holder.pepStatus,
        phone: holder.phone,
        proof: holder.proof,
        relation: holder.relation,
        taxCountry: holder.taxCountry,
        taxReference: holder.taxReference,
        taxResidency: holder.taxResidency,
      });
    });
  }

  if (state.bankDetails.bankRecord) {
    state.bankDetails.bankRecord.map((account) => {
      accounts.push({
        type: account.type,
        accountNumber: account.accountNumber,
        ifsc: account.ifsc.toUpperCase(),
        micr: account.micr,
      });
    });
  }

  if (state.nomineeDetails.nomineeRecord) {
    state.nomineeDetails.nomineeRecord.map((nominee) => {
      nominees.push({
        name: nominee.nomineeName,
        relation: nominee.relation,
        allocation: nominee.allocation,
        dob: nominee.dob,
        guardianName: nominee.guardianName,
        guardianRelation: nominee.guardianRelation,
        guardianDOB: nominee.guardianDOB,
      });
    });
  }

  let payload = {
    id: state.id,
    form: state.form,
    email: state.email,
    contact: state.contact,
    ipAddress: state.ipAddress,
    holderDetails: {
      type: state.holderDetails.type,
      taxStatus: state.holderDetails.taxStatus,
      category: state.holderDetails.category,
      holderRecord: holders,
    },
    bankDetails: {
      bankRecord: accounts,
    },
    nomineeDetails: {
      nomineeRecord: nominees,
    },
  };
  return JSON.stringify(payload);
};

export const mapAPIToState = (response) => {
  let holders = [];
  let accounts = [];
  let nominees = [];

  if (response.holderDetails) {
    response.holderDetails.holderRecord.map((holder) => {
      holders.push({
        name: holder.name ? holder.name : "",
        birthCity: holder.birthCity ? holder.birthCity : "",
        birthCountry: holder.birthCountry ? holder.birthCountry : "",
        citizenship: holder.citizenship ? holder.citizenship : "",
        dob: holder.dob ? holder.dob : "",
        email: holder.email ? holder.email : "",
        identityType: holder.identityType ? holder.identityType : "",
        income: holder.income ? holder.income : "",
        nationality: holder.nationality ? holder.nationality : "",
        occupation: holder.occupation ? holder.occupation : "",
        pan: holder.pan ? holder.pan : "",
        pepStatus: holder.pepStatus ? holder.pepStatus : "",
        phone: holder.phone ? holder.phone : "",
        proof: holder.proof ? holder.proof : "",
        relation: holder.relation ? holder.relation : "",
        taxCountry: holder.taxCountry ? holder.taxCountry : "",
        taxReference: holder.taxReference ? holder.taxReference : "",
        taxResidency: holder.taxResidency ? holder.taxResidency : "",
        type: holder.type ? holder.type : "",
      });
    });
  }

  if (response.bankDetails) {
    response.bankDetails.bankRecord.map((account) => {
      accounts.push({
        type: account.type ? account.type : "",
        accountNumber: account.accountNumber ? account.accountNumber : "",
        ifsc: account.ifsc ? account.ifsc : "",
        micr: account.micr ? account.micr : "",
      });
    });
  }

  if (response.nomineeDetails) {
    response.nomineeDetails.nomineeRecord.map((nominee) => {
      nominees.push({
        nomineeName: nominee.name ? nominee.name : "",
        dob: nominee.dob ? nominee.dob : "",
        relation: nominee.relation ? nominee.relation : "",
        allocation: nominee.allocation ? nominee.allocation : 0,
        guardianDOB: nominee.guardianDOB ? nominee.guardianDOB : "",
        guardianName: nominee.guardianName ? nominee.guardianName : "",
        guardianRelation: nominee.guardianRelation
          ? nominee.guardianRelation
          : "",
      });
    });
  }

  let state = {
    id: response.id,
    form: response.form,
    email: response.email,
    contact: response.contact,
    ipAddress: response.ipAddress,
    kycCompliance: response.kycCompliance,
    holderDetails: {
      type: response.holderDetails.type,
      taxStatus: response.holderDetails.taxStatus,
      category: response.holderDetails.category,
      holderRecord: holders,
    },
    bankDetails: {
      bankRecord: accounts,
    },
    nomineeDetails: {
      nomineeRecord: nominees,
    },
    documentsUploadStatus: {
      pan: response.documentsUploadStatus.pan,
      bankStatement: response.documentsUploadStatus.bankStatement,
      aadhaar: response.documentsUploadStatus.aadhaar,
      passportPhoto: response.documentsUploadStatus.passportPhoto,
      kycDocument: response.documentsUploadStatus.kycDocument,
      visa: response.documentsUploadStatus.visa,
      passport: response.documentsUploadStatus.passport,
    },
  };

  return state;
};

export const isNRIInvestor = (taxStatus) => {
  switch (taxStatus) {
    case "NRI":
      return true;
    case "NRM":
      return true;
    case "NNI":
      return true;
    case "NNM":
      return true;
    default:
      return false;
  }
};

export const isSingleHolding = (type) => {
  switch (type) {
    case "JO":
      return false;
    case "AS":
      return false;
    case "SI":
      return true;
    default:
      return true;
  }
};

export const getHolderRole = (type) => {
  switch (type) {
    case PRIMARY:
      return "Primary Holder";
    case SECONDARY:
      return "Secondary Holder";
    case THIRD:
      return "Third Holder";
    case GUARDIAN:
      return "Guardian Holder";
    default:
      return "Primary Holder";
  }
};

export const isMinorDOB = (dob) => {
  let currentYear = new Date().getFullYear();
  let dobYear = parseInt(dob.substr(0, 4));
  if (Math.abs(currentYear - dobYear) < 18) return true;
  else return false;
};

export const isFileRequired = (
  fileName,
  taxStatus,
  kycCompliance,
  documentStatus
) => {
  const isSubmitted = documentStatus[snakeToCamelCase(fileName)];
  const mfuMandatoryDocs = [DOC_PAN, DOC_BANK_STATEMENT];
  const residentKYCDocs = [
    DOC_KYC_FORM,
    DOC_AADHAAR,
    DOC_PASSPORT_SIZE_PHOTO,
  ];
  const nriKYCDocs = [
    DOC_KYC_FORM,
    DOC_AADHAAR,
    DOC_VISA,
    DOC_PASSPORT,
    DOC_PASSPORT_SIZE_PHOTO,
  ];

  if (mfuMandatoryDocs.includes(fileName) && !isSubmitted) return true;
  if (kycCompliance != "Y") {
    switch (taxStatus) {
      case "RI":
        if (residentKYCDocs.includes(fileName) && !isSubmitted) return true;
        break;
      case "NNI":
      case "NRI":
        if (nriKYCDocs.includes(fileName) && !isSubmitted) return true;
        break;
    }
  }
  return false;
};

function snakeToCamelCase(str) {
  return str.replace(/_([a-z])/g, function (match, group1) {
    return group1.toUpperCase();
  });
}
