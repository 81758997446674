import { useState, useEffect } from "react";
import { Header, Footer, Range } from "../../components";
import { CalculatorList } from "./Components";
import { Row, Col, Container } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { formatAmount } from "../../services/Utilities";
import {
  goalSipCalculator,
  validateGoalCalculator,
} from "../../services/CalculatorServices";

function GoalCalculator() {
  const [goalCalculator, setGoalCalculator] = useState({
    goalAmount: 2000000,
    duration: 10,
    rate: 12,
    investmentAmount: 0,
    sipAmount: 0,
    earnings: 0,
  });

  const goalChartData = {
    datasets: [
      {
        labels: ["Earnings", "Total Investment"],
        data: [
          Math.round(goalCalculator.earnings),
          Math.round(goalCalculator.investmentAmount),
        ],
        backgroundColor: ["#59aa00", "#0080ff"],
      },
    ],
  };

  function calculateGoal() {
    let error = validateGoalCalculator(goalCalculator);
    if (error === "") {
      let goalResult = goalSipCalculator(
        goalCalculator.goalAmount,
        goalCalculator.rate,
        goalCalculator.duration
      );
      setGoalCalculator({
        ...goalCalculator,
        error: false,
        investmentAmount: goalResult.investment,
        sipAmount: goalResult.pmtValue,
        earnings: goalResult.earnings,
      });
    }
  }

  const handleAmount = (e) => {
    setGoalCalculator({
      ...goalCalculator,
      goalAmount: e.target.value,
    });
  };

  const handleDuration = (e) => {
    setGoalCalculator({
      ...goalCalculator,
      duration: e.target.value,
    });
  };

  const handleRate = (e) => {
    setGoalCalculator({
      ...goalCalculator,
      rate: e.target.value,
    });
  };

  /** display goal calculator by default **/
  useEffect(() => {
    let goalResult = goalSipCalculator(
      goalCalculator.goalAmount,
      goalCalculator.rate,
      goalCalculator.duration
    );
    setGoalCalculator({
      ...goalCalculator,
      investmentAmount: goalResult.investment,
      sipAmount: goalResult.pmtValue,
      earnings: goalResult.earnings,
    });
  }, []);

  /* calculate the latest value when there is a change in amount,duration or rate */
  useEffect(() => {
    calculateGoal();
  }, [goalCalculator.goalAmount, goalCalculator.duration, goalCalculator.rate]);

  return (
    <>
      <Header page="goalcalculator" />
      <Container className="content">
        <h2>Goal Calculator</h2>
        <p>
          Determine the monthly SIP investments you need to make to reach a
          particular goal.
        </p>
      </Container>
      <Container className="calculators">
        <Row>
          <Col lg={9}>
            <div className="tool-container">
              <Row>
                <Col>
                  <Range
                    min={1000}
                    max={100000000}
                    step={1000}
                    current={goalCalculator.goalAmount}
                    label="Goal Amount"
                    handler={handleAmount}
                  />
                  <Range
                    min={1}
                    max={30}
                    step={1}
                    current={goalCalculator.duration}
                    label="Goal Duration (years)"
                    handler={handleDuration}
                  />
                  <Range
                    min={1}
                    max={30}
                    step={1}
                    current={goalCalculator.rate}
                    label="Expected Return % (p.a)"
                    handler={handleRate}
                  />
                </Col>
                <Col>
                  <Doughnut
                    type="doughnut"
                    data={goalChartData}
                    options={{ maintainAspectRatio: true }}
                  />
                  <br />
                  <h5 className="text-center">
                    Monthly SIP : {formatAmount(goalCalculator.sipAmount)}
                  </h5>
                  <h6 className="text-center">
                    <font color="#59aa00">Earnings : </font>
                    {formatAmount(goalCalculator.earnings)}
                  </h6>
                  <h6 className="text-center">
                    <font color="#0080ff">Total Investment : </font>
                    {formatAmount(goalCalculator.investmentAmount)}
                  </h6>
                  <br />
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={3}>
            <CalculatorList />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}
export default GoalCalculator;
