function Returnsbar(props) {
  const defaultBarWidth = 40.0;
  let returns = {
    return: props.return != null ? props.return : 0,
    absReturn: Math.abs(props.return),
  };
  let barWidth = {
    investment: defaultBarWidth,
    positive:
      returns.absReturn > 1 ? (defaultBarWidth * returns.absReturn) / 100 : 2,
    negative: returns.absReturn > 1 ? returns.absReturn : 2,
  };
  if (returns.return < 0) {
    barWidth.investment =
      defaultBarWidth - (defaultBarWidth * returns.absReturn) / 100;
    barWidth.negative = (defaultBarWidth * returns.absReturn) / 100;
  }
  let investmentBar = (
    <div
      className="return-bar-investment"
      style={{
        width: String(barWidth.investment) + "%",
      }}
    />
  );
  let positiveBar = (
    <>
      <div
        className="return-bar-positive"
        style={{ width: String(barWidth.positive) + "%" }}
      ></div>
      <div className="return-bar-positive-label">{returns.return + "%"}</div>
    </>
  );
  let negativeBar = (
    <>
      <div
        className="return-bar-negative"
        style={{ width: String(barWidth.negative) + "%" }}
      ></div>
      <div className="return-bar-negative-label">{returns.return + "%"}</div>
    </>
  );
  let defaultBar = (
    <>
      <div
        className="return-bar-default"
        style={{ width: String(defaultBarWidth) + "%" }}
      ></div>
      <div className="return-bar-default-label">NA</div>
    </>
  );

  const showReturnBar = () => {
    let returnBar = [];
    if (returns.return > 0) {
      returnBar.push(investmentBar, positiveBar);
    } else if (returns.return < 0) {
      returnBar.push(investmentBar, negativeBar);
    } else {
      returnBar.push(defaultBar);
    }
    return returnBar;
  };
  return <div className="return-bar-wrapper">{showReturnBar()}</div>;
}
export default Returnsbar;
