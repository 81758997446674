import "../theme/styles.scss";
import { Row, Col, Container } from "react-bootstrap";

/* code splitting for performance */
import loadable from '@loadable/component'
const Header = loadable(() => import('../components/Header'))
const Footer = loadable(() => import('../components/Footer'))



function About() {
  return (
    <>
      <Header page="about"/>
      <Container className="about-section">
        <h1 className="text-center">About us</h1>
        <br />
        <br />
        <Row>
          <Col>
            <p>
              Kashly is all about trust. Our customers are our family, and they
              choose us to guide them in their financial journey. Our experts
              step into the shoes of the investor and understand their problem.
              We give them solutions, not just mutual fund advice. We are a
              customer focused, transparent organization. <br />
              <br />
              We are part of the Indian Mutual Funds ecosystem with a hybrid
              model of operation. We synchronize the cognitive power of humans
              with technology to get the best financial plans. We know the pain
              behind earning each penny and the emotional connection an investor
              has with his/her hard-earned money. So, we believe just a
              robo-advisor or any other technology product is not enough to
              manage his/her lifetime earnings and should be dealt with the same
              emotion.
              <br />
              <br />
              We are a group of talented financial professionals who work
              closely with the industry because of our passion rather than
              considering Kashly as a business. Yes, we have skin in the game.
              Kashly is a wholly owned platform of Capsquare Technologies
              Private Limited.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default About;
