function SkeletonText({width,height}) {
  return (
    <span
      style={{
        width: width,
        margin:"10px 0",
        height: height,
        display: "block",
        background: "linear-gradient(to right,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.5) 50%,rgba(255, 255, 255, 0) 80%),#f1f1f1",
        backgroundRepeat: "repeat - y",
        backgroundSize: "150px 500px",
        backgroundPosition: "0 0",
        animation: "shine 1s infinite",
      }}
    >
    </span>
  );
}
export default SkeletonText;
