import { useState, useEffect } from "react";
import { Header, Footer, Range } from "../../components";
import { CalculatorList } from "./Components";
import { Row, Col, Container } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { formatAmount } from "../../services/Utilities";
import {
  inflationCalculator,
  validateInflationCalculator,
} from "../../services/CalculatorServices";

function InflationCalculator() {
  const [calculator, setCalculator] = useState({
    amount: 30000,
    duration: 10,
    rate: 7,
    InflatedValue: 0,
  });

  const inflationChartData = {
    datasets: [
      {
        labels: ["Current Value", "Inflated Value"],
        data: [
          Math.round(calculator.amount),
          Math.round(calculator.InflatedValue),
        ],
        backgroundColor: ["#59aa00", "#0080ff"],
      },
    ],
  };

  function calculateInflation() {
    let error = validateInflationCalculator(calculator);
    if (error === "") {
      var inflation = inflationCalculator(
        calculator.amount,
        calculator.rate,
        calculator.duration
      );

      setCalculator({
        ...calculator,
        error: false,
        InflatedValue: inflation,
      });
    }
  }

  const handleAmount = (e) => {
    setCalculator({
      ...calculator,
      amount: e.target.value,
    });
  };

  const handleDuration = (e) => {
    setCalculator({
      ...calculator,
      duration: e.target.value,
    });
  };

  const handleRate = (e) => {
    setCalculator({
      ...calculator,
      rate: e.target.value,
    });
  };

  /** display goal calculator by default **/
  useEffect(() => {
    var inflation = inflationCalculator(
      calculator.amount,
      calculator.rate,
      calculator.duration
    );
    setCalculator({
      ...calculator,
      error: false,
      InflatedValue: inflation,
    });
  }, []);

  /* calculate the latest value when there is a change in amount,duration or rate */
  useEffect(() => {
    calculateInflation();
  }, [calculator.amount, calculator.rate, calculator.duration]);

  return (
    <>
      <Header page="inflationcalculator" />
      <Container className="content">
        <h2>Inflation Calculator</h2>
        <p>
          How much you will need in the future to meet your current expenses
          against inflation.
        </p>
      </Container>

      <Container className="calculators">
        <Row>
          <Col lg={9}>
            <div className="tool-container">
              <Row>
                <Col>
                  {" "}
                  <Range
                    min={1000}
                    max={10000000}
                    step={1000}
                    current={calculator.amount}
                    label="Value of Current Expenses"
                    handler={handleAmount}
                  />
                  <Range
                    min={1}
                    max={30}
                    step={1}
                    current={calculator.duration}
                    label="Time Period(Years)"
                    handler={handleDuration}
                  />
                  <Range
                    min={1}
                    max={30}
                    step={1}
                    current={calculator.rate}
                    label="Annual Inflation Rate %"
                    handler={handleRate}
                  />
                </Col>
                <Col>
                  <Doughnut
                    type="doughnut"
                    data={inflationChartData}
                    options={{ maintainAspectRatio: true }}
                  />
                  <br />
                  <h5 className="text-center">
                    <font color="#0080ff">Inflated Value : </font>
                    {formatAmount(calculator.InflatedValue)}
                  </h5>
                  <h6 className="text-center">
                    <font color="#59aa00">Current Value : </font>
                    {formatAmount(calculator.amount)}
                  </h6>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={3}>
            <CalculatorList />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
export default InflationCalculator;
