import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  GET_START,
  HOLDING_FORM,
  BANK_FORM,
  NOMINEE_FORM,
  DOCUMENT_FORM,
  SUBMIT_FORM,
  SIGNUP_URL,
} from "../constants";

import API from "../../api";
import { Footer, Header } from "../../components";
import BankDetails from "./BankDetails";
import Holder from "./Holder";
import Nominee from "./Nominee";
import Document from "./Document";
import Status from "./Status";
import Loader from "./Loader";
import { mapAPIToState } from "./utils";
import { isEmail } from "../validations";
import "./Application.scss";
import GetStart from "./GetStart";

function Application() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication] = useState({
    id: "",
    form: GET_START,
    email: "",
    kycCompliance: "",
    ipAddress: "",
    holderDetails: {
      type: "",
      taxStatus: "",
      category: "",
      holderRecord: [],
    },
    bankDetails: {
      bankRecord: [],
    },
    nomineeDetails: {
      nomineeRecord: [],
    },
    documentsUploadStatus: {
      pan: false,
      bankStatement: false,
      addressProof: false,
      passportPhoto: false,
      kycDocument: false,
      visa: false,
      passport: false,
    },
  });

  useEffect(() => {
    if (typeof id != "undefined" && isEmail(id)) {
      API.getApplication(id)
        .then((result) => {
          if (result.status == 200 && result.data.data.isVerified == "Y") {
            setApplication(mapAPIToState(result.data.data));
          } else {
            window.location.href = SIGNUP_URL;
          }
        })
        .catch((error) => {
          window.location.href = SIGNUP_URL;
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      window.location.href = SIGNUP_URL;
    }
  }, [id]);

  const showApplication = (form) => {
    switch (form) {
      case GET_START:
        return (
          <GetStart application={application} setApplication={setApplication} />
        );
      case HOLDING_FORM:
        return (
          <Holder application={application} setApplication={setApplication} />
        );

      case BANK_FORM:
        return (
          <BankDetails
            application={application}
            setApplication={setApplication}
          />
        );

      case NOMINEE_FORM:
        return (
          <Nominee application={application} setApplication={setApplication} />
        );
      case DOCUMENT_FORM:
        return (
          <Document application={application} setApplication={setApplication} />
        );
      case SUBMIT_FORM:
        return (
          <Status application={application} setApplication={setApplication} />
        );
      default:
        return (
          <GetStart application={application} setApplication={setApplication} />
        );
    }
  };

  return (
    <>
      <Header />
      {isLoading ? <Loader /> : showApplication(application.form)}
      <Footer />
    </>
  );
}

export default Application;
