import { Row, Col, Container } from "react-bootstrap";

const Status = ({ application, setApplication }) => {
  return (
    <Container className="application-wrapper center-content">
      <Row>
        <Col>
          <img
            className="confetti-icon"
            src="/assets/images/confetti.gif"
            alt="Kashly Online Application"
          />
          <h2>Congratulations, We're done!</h2>
          <p>We have received your application</p>
          <br />
          <h4>What's next ? </h4>
          <p>
            We will review your application carefully and activate your account
            as early as we can. If the submitted documents or KYC information is
            not in order, we will reach you.
          </p>
          <p>
            Please write to{" "}
            <a href="mailto:support@kashly.in">support@kashly.in</a> for any
            queries on your application
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default Status;
