import { Form } from "react-bootstrap";

const Field = ({
  type,
  name,
  label,
  value,
  options,
  placeholder,
  onChange,
}) => {
  switch (type) {
    case "text":
    case "email":
    case "date":
    case "number":
      return (
        <Form.Group controlId={name}>
          <Form.Label className="required-field">{label}</Form.Label>
          <Form.Control
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
          />
        </Form.Group>
      );
    case "select":
      return (
        <Form.Group controlId={name}>
          <Form.Label className="required-field">{label}</Form.Label>
          <Form.Control
            as={type}
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
          >
            {options}
          </Form.Control>
        </Form.Group>
      );
    case "checkbox":
      return (
        <Form.Group controlId={name}>
          <Form.Check type={type}>
            <Form.Check.Input
              type={type}
              name={name}
              checked={value}
              onChange={(e) => onChange(e)}
            />
            <Form.Check.Label>{label}</Form.Check.Label>
          </Form.Check>
        </Form.Group>
      );
    default:
      return null;
  }
};
export default Field;
