import "../theme/styles.scss";
import { Container } from "react-bootstrap";

/* code splitting for performance */
import loadable from '@loadable/component'
const Header = loadable(() => import('../components/Header'))
const Footer = loadable(() => import('../components/Footer'))

function Refund() {
  return (
    <>
      <Header page="refund" />
      <Container>
        <br />
        <br />
        <h4>Kashly Refund Policy</h4>
        <br/><br/>
        <h6>Refunds</h6>
        <p>
          Once you have applied for a refund, by sending a mail to
          support@kashly.in (If applicable as mentioned at the time of accepting
          the service), we will notify you of the approval or rejection of your
          refund. If you are approved, then your refund will be processed, and a
          credit will automatically be applied to your credit card or original
          method of payment, within a certain amount of days.
        </p><br/>
        <h6>Late or missing refunds</h6>
        <p>If you haven't received a refund yet, firstcheck your bank account again. Then contact your credit card company, it
        may take some time before your refund is officially posted. Next contact your bank. There is often some processing time before a refund is
        posted.<br/><br/>If you've done all of this and you still have not received your
        refund yet, please contact us at support@kashly.in Make sure that you
        are reading the terms of service properly before availing the services
        offered by the company. Refund is applicable only to selected services
        only.</p><br/><br/>
      </Container>
      <Footer />
    </>
  );
}

export default Refund;
