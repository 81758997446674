import { isNRIInvestor } from "./utils";

export const taxStatusOptions = () => {
  return (
    <>
      <option value="">Select One</option>
      <option value="RI">Resident Indian</option>
      <option value="NNI">Non Resident Indian - NRO</option>
      <option value="NRI">Non Resident Indian - NRE</option>
    </>
  );
};

export const taxResOptions = () => {
  return (
    <>
      <option value="">Select One</option>
      <option value="N">No</option>
      <option value="Y">Yes</option>
    </>
  );
};

export const holdingTypeOptions = () => {
  /*
  <option value="JO">Joint Account</option>
  <option value="AS">Anyone/Survivor</option>
  */
  return (
    <>
      <option value="">Select One</option>
      <option value="SI">Single Account</option>
    </>
  );
};

export const identityOptions = () => {
  return (
    <>
      <option value="">Choose one</option>
      <option value="A">Passport</option>
      <option value="B">Election ID Card</option>
      <option value="C">PAN Card</option>
      <option value="D">ID Card</option>
      <option value="G">UIDIA/Aadhar Letter</option>
      <option value="H">NREGA Job Card</option>
      <option value="T">TIN</option>
    </>
  );
};

export const countryOptions = () => {
  return (
    <>
      <option value="">Select One</option>
      <option value="101">India</option>
      <option value="001">Afghanistan</option>
      <option value="002">Aland Islands</option>
      <option value="003">Albania</option>
      <option value="004">Algeria</option>
      <option value="005">American Samoa</option>
      <option value="006">Andorra</option>
      <option value="007">Angola</option>
      <option value="008">Anguilla</option>
      <option value="009">Antarctica</option>
      <option value="010">Antigua And Barbuda</option>
      <option value="011">Argentina</option>
      <option value="012">Armenia</option>
      <option value="013">Aruba</option>
      <option value="014">Australia</option>
      <option value="015">Austria</option>
      <option value="016">Azerbaijan</option>
      <option value="017">Bahamas</option>
      <option value="018">Bahrain</option>
      <option value="019">Bangladesh</option>
      <option value="020">Barbados</option>
      <option value="021">Belarus</option>
      <option value="022">Belgium</option>
      <option value="023">Belize</option>
      <option value="024">Benin</option>
      <option value="025">Bermuda</option>
      <option value="026">Bhutan</option>
      <option value="027">Bolivia</option>
      <option value="028">Bosnia And Herzegovina</option>
      <option value="029">Botswana</option>
      <option value="030">Bouvet Island</option>
      <option value="031">Brazil</option>
      <option value="032">British Indian Ocean Territory</option>
      <option value="033">Brunei Darussalam</option>
      <option value="034">Bulgaria</option>
      <option value="035">Burkina Faso</option>
      <option value="036">Burundi</option>
      <option value="037">Cambodia</option>
      <option value="038">Cameroon</option>
      <option value="039">Canada</option>
      <option value="040">Cape Verde</option>
      <option value="041">Cayman Islands</option>
      <option value="042">Central African Republic</option>
      <option value="043">Chad</option>
      <option value="044">Chile</option>
      <option value="045">China</option>
      <option value="046">Christmas Island</option>
      <option value="047">Cocos (Keeling) Islands</option>
      <option value="048">Colombia</option>
      <option value="049">Comoros</option>
      <option value="050">Congo</option>
      <option value="051">Congo, The Democratic Republic Of The</option>
      <option value="052">Cook Islands</option>
      <option value="053">Costa Rica</option>
      <option value="054">Cote D Ivoire</option>
      <option value="055">Croatia</option>
      <option value="056">Cuba</option>
      <option value="057">Cyprus</option>
      <option value="058">Czech Republic</option>
      <option value="059">Denmark</option>
      <option value="060">Djibouti</option>
      <option value="061">Dominica</option>
      <option value="062">Dominican Republic</option>
      <option value="063">Ecuador</option>
      <option value="064">Egypt</option>
      <option value="065">El Salvador</option>
      <option value="066">Equatorial Guinea</option>
      <option value="067">Eritrea</option>
      <option value="068">Estonia</option>
      <option value="069">Ethiopia</option>
      <option value="070">Falkland Islands (Malvinas)</option>
      <option value="071">Faroe Islands</option>
      <option value="072">Fiji</option>
      <option value="073">Finland</option>
      <option value="074">France</option>
      <option value="075">French Guiana</option>
      <option value="076">French Polynesia</option>
      <option value="077">French Southern Territories</option>
      <option value="078">Gabon</option>
      <option value="079">Gambia</option>
      <option value="080">Georgia</option>
      <option value="081">Germany</option>
      <option value="082">Ghana</option>
      <option value="083">Gibraltar</option>
      <option value="084">Greece</option>
      <option value="085">Greenland</option>
      <option value="086">Grenada</option>
      <option value="087">Guadeloupe</option>
      <option value="088">Guam</option>
      <option value="089">Guatemala</option>
      <option value="090">Guernsey</option>
      <option value="091">Guinea</option>
      <option value="092">Guinea-Bissau</option>
      <option value="093">Guyana</option>
      <option value="094">Haiti</option>
      <option value="095">Heard Island And Mcdonald Islands</option>
      <option value="096">Holy See (Vatican City State)</option>
      <option value="097">Honduras</option>
      <option value="098">Hong Kong</option>
      <option value="099">Hungary</option>
      <option value="100">Iceland</option>
      <option value="101">India</option>
      <option value="102">Indonesia</option>
      <option value="103">Iran, Islamic Republic Of</option>
      <option value="104">Iraq</option>
      <option value="105">Ireland</option>
      <option value="106">Isle Of Man</option>
      <option value="107">Israel</option>
      <option value="108">Italy</option>
      <option value="109">Jamaica</option>
      <option value="110">Japan</option>
      <option value="111">Jersey</option>
      <option value="112">Jordan</option>
      <option value="113">Kazakhstan</option>
      <option value="114">Kenya</option>
      <option value="115">Kiribati</option>
      <option value="116">Korea, Democratic Peoples Republic Of</option>
      <option value="117">Korea, Republic Of</option>
      <option value="118">Kuwait</option>
      <option value="119">Kyrgyzstan</option>
      <option value="120">Lao Peoples Democratic Republic</option>
      <option value="121">Latvia</option>
      <option value="122">Lebanon</option>
      <option value="123">Lesotho</option>
      <option value="124">Liberia</option>
      <option value="125">Libyan Arab Jamahiriya</option>
      <option value="126">Liechtenstein</option>
      <option value="127">Lithuania</option>
      <option value="128">Luxembourg</option>
      <option value="129">Macao</option>
      <option value="130">Macedonia, The Former Yugoslav Republic Of</option>
      <option value="131">Madagascar</option>
      <option value="132">Malawi</option>
      <option value="133">Malaysia</option>
      <option value="134">Maldives</option>
      <option value="135">Mali</option>
      <option value="136">Malta</option>
      <option value="137">Marshall Islands</option>
      <option value="138">Martinique</option>
      <option value="139">Mauritania</option>
      <option value="140">Mauritius</option>
      <option value="141">Mayotte</option>
      <option value="142">Mexico</option>
      <option value="143">Micronesia, Federated States Of</option>
      <option value="144">Moldova, Republic Of</option>
      <option value="145">Monaco</option>
      <option value="146">Mongolia</option>
      <option value="147">Montserrat</option>
      <option value="148">Morocco</option>
      <option value="149">Mozambique</option>
      <option value="150">Myanmar</option>
      <option value="151">Namibia</option>
      <option value="152">Nauru</option>
      <option value="153">Nepal</option>
      <option value="154">Netherlands</option>
      <option value="155">Netherlands Antilles</option>
      <option value="156">New Caledonia</option>
      <option value="157">New Zealand</option>
      <option value="158">Nicaragua</option>
      <option value="159">Niger</option>
      <option value="160">Nigeria</option>
      <option value="161">Niue</option>
      <option value="162">Norfolk Island</option>
      <option value="163">Northern Mariana Islands</option>
      <option value="164">Norway</option>
      <option value="165">Oman</option>
      <option value="166">Pakistan</option>
      <option value="167">Palau</option>
      <option value="168">Palestinian Territory, Occupied</option>
      <option value="169">Panama</option>
      <option value="170">Papua New Guinea</option>
      <option value="171">Paraguay</option>
      <option value="172">Peru</option>
      <option value="173">Philippines</option>
      <option value="174">Pitcairn</option>
      <option value="175">Poland</option>
      <option value="176">Portugal</option>
      <option value="177">Puerto Rico</option>
      <option value="178">Qatar</option>
      <option value="179">Reunion</option>
      <option value="180">Romania</option>
      <option value="181">Russian Federation</option>
      <option value="182">Rwanda</option>
      <option value="183">Saint Helena</option>
      <option value="184">Saint Kitts And Nevis</option>
      <option value="185">Saint Lucia</option>
      <option value="186">Saint Pierre And Miquelon</option>
      <option value="187">Saint Vincent And The Grenadines</option>
      <option value="188">Samoa</option>
      <option value="189">San Marino</option>
      <option value="190">Sao Tome And Principe</option>
      <option value="191">Saudi Arabia</option>
      <option value="192">Senegal</option>
      <option value="193">Serbia And Montenegro</option>
      <option value="194">Seychelles</option>
      <option value="195">Sierra Leone</option>
      <option value="196">Singapore</option>
      <option value="197">Slovakia</option>
      <option value="198">Slovenia</option>
      <option value="199">Solomon Islands</option>
      <option value="200">Somalia</option>
      <option value="201">South Africa</option>
      <option value="202">South Georgia And The South Sandwich Islands</option>
      <option value="203">Spain</option>
      <option value="204">Sri Lanka</option>
      <option value="205">Sudan</option>
      <option value="206">Suriname</option>
      <option value="207">Svalbard And Jan Mayen</option>
      <option value="208">Swaziland</option>
      <option value="209">Sweden</option>
      <option value="210">Switzerland</option>
      <option value="211">Syrian Arab Republic</option>
      <option value="212">Taiwan, Province Of China</option>
      <option value="213">Tajikistan</option>
      <option value="214">Tanzania, United Republic Of</option>
      <option value="215">Thailand</option>
      <option value="216">Timor-Leste</option>
      <option value="217">Togo</option>
      <option value="218">Tokelau</option>
      <option value="219">Tonga</option>
      <option value="220">Trinidad And Tobago</option>
      <option value="221">Tunisia</option>
      <option value="222">Turkey</option>
      <option value="223">Turkmenistan</option>
      <option value="224">Turks And Caicos Islands</option>
      <option value="225">Tuvalu</option>
      <option value="226">Uganda</option>
      <option value="227">Ukraine</option>
      <option value="228">United Arab Emirates</option>
      <option value="229">United Kingdom</option>
      <option value="230">United States</option>
      <option value="231">United States Minor Outlying Islands</option>
      <option value="232">Uruguay</option>
      <option value="233">Uzbekistan</option>
      <option value="234">Vanuatu</option>
      <option value="235">Venezuela</option>
      <option value="236">Viet Nam</option>
      <option value="237">Virgin Islands, British</option>
      <option value="238">Virgin Islands, U.S.</option>
      <option value="239">Wallis And Futuna</option>
      <option value="240">Western Sahara</option>
      <option value="241">Yemen</option>
      <option value="242">Zambia</option>
      <option value="243">Zimbabwe</option>
    </>
  );
};

export const occupationOptions = () => {
  return (
    <>
      <option value="">Select One</option>
      <option value="01">Private Sector Service</option>
      <option value="02">Public Sector</option>
      <option value="03">Business</option>
      <option value="04">Professional</option>
      <option value="05">Agriculturist</option>
      <option value="06">Retired</option>
      <option value="07">Housewife</option>
      <option value="08">Student</option>
      <option value="09">Forex Dealer</option>
      <option value="10">Government Service</option>
      <option value="11">Doctor</option>
    </>
  );
};

export const incomeOptions = () => {
  return (
    <>
      <option value="">Select One</option>
      <option value="01">Below 1 Lac</option>
      <option value="02">1 - 5 Lac</option>
      <option value="03">5 - 10 Lac</option>
      <option value="04">10 - 25 Lac</option>
      <option value="05">25 Lac - 1 Cr</option>
      <option value="06">Greater than 1 Cr</option>
    </>
  );
};
export const pepOptions = () => {
  return (
    <>
      <option value="">Select One</option>
      <option value="NA">Not Applicable</option>
      <option value="PEP">Politically Exposed Person</option>
      <option value="PREP">Related to Politically Exposed Person</option>
    </>
  );
};

export const accountOptions = (taxStatus) => {
  if (isNRIInvestor(taxStatus)) {
    return (
      <>
        <option value="">Select One</option>
        <option value="NRO">Non Resident Ordinary (NRO)</option>
        <option value="NRE">Non Resident External (NRE)</option>
      </>
    );
  } else {
    return (
      <>
        <option value="">Select One</option>
        <option value="SB">Savings Bank</option>
        <option value="CA">Current Account</option>
      </>
    );
  }
};

export const nomineeOptions = () => {
  return (
    <>
      <option value="">Please Select</option>
      <option value="Husband">Husband</option>
      <option value="Wife">Wife</option>
      <option value="Father">Father</option>
      <option value="Mother">Mother</option>
      <option value="Son">Son</option>
      <option value="Daughter">Daughter</option>
      <option value="Brother">Brother</option>
      <option value="Sister">Sister</option>
    </>
  );
};

export const guardianOptions = () => {
  return (
    <>
      <option value="">Please Select</option>
      <option value="Father">Father</option>
      <option value="Mother">Mother</option>
    </>
  );
};
