import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";

import API from "../../api";

import { mapStateToAPI } from "./utils";
import { accountOptions } from "./options";
import { HOLDING_FORM, NOMINEE_FORM } from "../constants";

import { validateBankAccount, vaidateIFSC } from "../validations";

import Field from "./Field";
import SectionHeader from "./SectionHeader";

const BankDetails = ({ application, setApplication }) => {
  const bankRecords = application.bankDetails.bankRecord;
  const [errorMessage, setErrorMesage] = useState("");
  const [bankInfo, setBankInfo] = useState("");
  const [accounts, setAccounts] = useState([
    {
      type: "",
      accountNumber: 0,
      ifsc: "",
      micr: "",
    },
  ]);

  useEffect(() => {
    if (bankRecords.length > 0) {
      let newState = [];
      bankRecords.map((account) => {
        newState.push({
          type: account.type,
          accountNumber: account.accountNumber,
          ifsc: account.ifsc,
          micr: account.micr,
        });
      });
      setAccounts(newState);
    }
  }, [application]);

  const handleContinue = () => {
    let validationError = validateBankAccount(accounts);
    if (validationError == "") {
      let newState = {
        ...application,
        form: NOMINEE_FORM,
        bankDetails: {
          bankRecord: accounts,
        },
      };
      setApplication(newState);
      modifyApplication(newState);
    }
    setErrorMesage(validationError);
  };

  const handleBack = () => {
    let newState = {
      ...application,
      form: HOLDING_FORM,
    };
    setApplication(newState);
  };

  const showErrorMessage = () => {
    return errorMessage !== "" ? (
      <Alert variant="danger">{errorMessage}</Alert>
    ) : null;
  };

  const showBankInfo = () => {
    return bankInfo !== "" ? <Alert variant="warning">{bankInfo}</Alert> : null;
  };

  const handleFieldChange = (index, event) => {
    let data = [...accounts];
    data[index][event.target.name] = event.target.value;
    if (event.target.name == "ifsc" && vaidateIFSC(event.target.value)) {
      API.getIFSCDetails(event.target.value)
        .then((result) => {
          setBankInfo(
            `${result.data.BANK}, ${result.data.BRANCH}, ${result.data.CITY}, ${result.data.STATE}`
          );
          data[index]["micr"] = result.data.MICR;
          setAccounts(data);
        })
        .catch((error) => {
          setBankInfo(
            `We couldn't recognize this IFSC code, however you can continue enter correct IFSC and MICR codes`
          );
          setAccounts(data);
        });
    } else {
      setAccounts(data);
    }
  };

  const modifyApplication = (state) => {
    API.updateApplication(application.id, mapStateToAPI(state))
      .then((result) => {})
      .catch((error) => {});
  };

  const AccountForm = () => {
    let accountForm;
    accounts.map((account, index) => {
      accountForm = (
        <>
          <Row>
            <Col lg={6} sm={12}>
              <Field
                type="select"
                name="type"
                label="Account type"
                options={accountOptions(application.holderDetails.taxStatus)}
                value={account.type ? account.type : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={6} sm={12}>
              <Field
                type="number"
                name="accountNumber"
                label="Account number"
                value={account.accountNumber ? account.accountNumber : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <Field
                type="text"
                name="ifsc"
                label="IFSC"
                value={account.ifsc ? account.ifsc : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={6} sm={12}>
              <Field
                type="number"
                name="micr"
                label="MICR Code"
                value={account.micr ? account.micr : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
          </Row>
        </>
      );
    });
    return accountForm;
  };

  return (
    <Container className="application-wrapper">
      <SectionHeader
        step="3"
        heading="Bank Account Details"
        subheading="Details of the bank account that you will use for invest in mutual funds."
      />
      {AccountForm()}
      <Row>
        <Col>{showBankInfo()}</Col>
      </Row>
      <Row>
        <Col>
          {showErrorMessage()}
          <Button onClick={handleBack}>Back</Button>
          <Button onClick={handleContinue}>Continue</Button>
        </Col>
      </Row>
    </Container>
  );
};
export default BankDetails;
