import {Header,Footer} from "../../components";
import {Container } from "react-bootstrap";
import { getMutualFundCalculators } from "../../services/CalculatorServices";

function Calculators() {
  return (
    <>
      <Header page="calculators"/>
      <Container className="content">
        <h2 className="text-center">Calculators</h2><br/><br/>
        {getMutualFundCalculators()}
      </Container>
      <Footer />
    </>
  );
}
export default Calculators;
