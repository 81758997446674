import { Col } from "react-bootstrap";
const style = {
  background: "#ffffff",
  borderRadius: "5px",
  padding: "1rem 2rem",
};
const Sidebar = ({ children }) => {
  return (
    <Col lg={4} xs={12}>
      <div style={style}>{children}</div>
    </Col>
  );
};
export default Sidebar;
