import { useState} from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";

/* code splitting for performance */
import loadable from '@loadable/component'
const Header = loadable(() => import('../components/Header'))
const Footer = loadable(() => import('../components/Footer'))


function RiskProfile() {
  const [showRiskCalculator, setShowRiskCalculator] = useState(false);

  function getRiskCalculator() {
    return (
      <Form>
        <Row>
          <Col>
            <Form.Group controlId="age">
              <Form.Label>What is your current age ?</Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="5">18 - 30 Years</option>
                <option value="4">31 - 40 Years</option>
                <option value="3">41 - 50 Years</option>
                <option value="2">51 - 60 Years</option>
                <option value="1">Above 60 Years</option>
              </Form.Control>
            </Form.Group>
            <br />
            <Form.Group controlId="dependents">
              <Form.Label>
                How many people depend on you financially?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="5">Nobody</option>
                <option value="4">1 Person</option>
                <option value="3">1 Person, But they too earn</option>
                <option value="2">2 to 3 Persons</option>
                <option value="1">More than 3 Persons</option>
              </Form.Control>
            </Form.Group>
            <br />

            <Form.Group controlId="income">
              <Form.Label>Your annual income? (INR)</Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Less than 2,50,000</option>
                <option value="2">2,00,000 – 5,00,000 </option>
                <option value="3">5,00,000 – 10,00,000 </option>
                <option value="4">10,00,000 – 15,00,000 </option>
                <option value="5">Above 15,00,000</option>
              </Form.Control>
            </Form.Group>
            <br />

            <Form.Group controlId="expenses">
              <Form.Label>
                What percentage of income goes into your expenses concerned with
                your lifestyle?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">More than 50%</option>
                <option value="2">50% to 30% </option>
                <option value="3">30% to 15%</option>
                <option value="4">Less than 15%</option>
                <option value="5">I have enough surplus</option>
              </Form.Control>
            </Form.Group>
            <br />

            <Form.Group controlId="incomeMode">
              <Form.Label>
                When you think about investments, whom do you consult with?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="5">Nobody</option>
                <option value="4">Family Members</option>
                <option value="3">Relatives</option>
                <option value="2">Friends</option>
                <option value="1">Financial Advisor</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="investmentExperience">
              <Form.Label>
                Describe your own experience level as an investor?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="0">I have had no experience at all</option>
                <option value="1">
                  I have no virtually experience in investing apart from using
                  banks accounts
                </option>
                <option value="2">
                  I have had limited experience in investing
                </option>
                <option value="3">I have had a reasonable experience</option>
                <option value="4">
                  I would consider myself as an experienced investor
                </option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflationKnowledge">
              <Form.Label>
                Are you aware of ‘Inflation’ and related things?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflationFluctuation">
              <Form.Label>
                Do you any idea about how can a fluctuation in ‘Inflation rate’
                affects an investment decision?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="highRisk">
              <Form.Label>
                Do you agree with the statement “high risk leads to high
                return”?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="riskAttitude">
              <Form.Label>
                What best describes your attitude towards doing something which
                is risky in nature?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="0">Totally blank</option>
                <option value="1">It is something to be avoided</option>
                <option value="2">
                  It is a source of uncertainty needs to be limited
                </option>
                <option value="3">
                  It can create the opportunity to improved returns
                </option>
                <option value="4">It is something to be embraced</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflation">
              <Form.Label>
                Do you have any previous investment history?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflation">
              <Form.Label>
                Thinking about the risk you have taken with your past investment
                choices; how would you describe the level of risk?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="5">High</option>
                <option value="4">Moderate</option>
                <option value="3">Low</option>
                <option value="2">Experience was limited</option>
                <option value="1">With no experience </option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflation">
              <Form.Label>
                What percentage of your income can be invested?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">0 to 10%</option>
                <option value="2">11% to 20% </option>
                <option value="3">21% to 30%</option>
                <option value="4">More than 30%</option>
                <option value="0">
                  I currently have no income for investing at all
                </option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflation">
              <Form.Label>
                Are you familiar with term ‘Investment Portfolio’?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflation">
              <Form.Label>
                You would start to worry about your investments, if/when it is
                value falls.
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Less than 5% per annum</option>
                <option value="2">05% to 10% per annum</option>
                <option value="4">10% to 20% per annum</option>
                <option value="4">20% to 30% per annum</option>
                <option value="5">More than 30%</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflation">
              <Form.Label>
                What is the expected rate of return from investments?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Around 6% per annum</option>
                <option value="0">Around 10% per annum</option>
                <option value="0">Around 12% per annum</option>
                <option value="0">Around 15% per annum</option>
                <option value="0">More than 15%</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="inflation">
              <Form.Label>
                When do you expect to liquidate your investment?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Less than 1 year</option>
                <option value="2">1 to 3 years</option>
                <option value="3">4 to 6 years</option>
                <option value="4">6 to 10 years</option>
                <option value="5">More than 10 years</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="inflation">
              <Form.Label>
                While creating an investment portfolio/basket, where you want to
                most likely to park your surplus?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Savings and fixed deposits</option>
                <option value="2">Bonds and/or Debt Securities</option>
                <option value="3">
                  Equities and Derivatives and/or Mutual Funds
                </option>
                <option value="4">Real estate </option>
                <option value="5">Diversified </option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="inflation">
              <Form.Label>
                For me, part of investing is just experiencing the ups and
                downs.
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Strongly Disagree</option>
                <option value="2">Disagree</option>
                <option value="3">Neither agree nor disagree</option>
                <option value="4">Agree</option>
                <option value="5">Strongly agree</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="inflation">
              <Form.Label>
                I prefer to keep capital safe rather than having higher returns
                by investing.
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="5">Strongly Disagree</option>
                <option value="4">Disagree</option>
                <option value="3">Neither agree nor disagree</option>
                <option value="2">Agree</option>
                <option value="1">Strongly agree</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="inflation">
              <Form.Label>
                I always over-react to unexpected bad financial news.
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="5">Strongly Disagree</option>
                <option value="4">Disagree</option>
                <option value="3">Neither agree nor disagree</option>
                <option value="2">Agree</option>
                <option value="1">Strongly agree</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="inflation">
              <Form.Label>
                I would still consider making risky investments even if I had
                made significant losses in the past.
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Strongly Disagree</option>
                <option value="2">Disagree</option>
                <option value="3">Neither agree nor disagree</option>
                <option value="4">Agree</option>
                <option value="5">Strongly agree</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="inflation">
              <Form.Label>
                I would be happy to tolerate negative fluctuation in the value
                of my investments to get a better return in the future.
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Strongly Disagree</option>
                <option value="2">Disagree</option>
                <option value="3">Neither agree nor disagree</option>
                <option value="4">Agree</option>
                <option value="5">Strongly agree</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="inflation">
              <Form.Label>
                I would get a thrill from making risky investments with my
                money.
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="5">Strongly Disagree</option>
                <option value="4">Disagree</option>
                <option value="3">Neither agree nor disagree</option>
                <option value="2">Agree</option>
                <option value="1">Strongly agree</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="inflation">
              <Form.Label>
                I am a person, who always tries to keep myself updated with
                investment matters.
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Strongly Disagree</option>
                <option value="2">Disagree</option>
                <option value="3">Neither agree nor disagree</option>
                <option value="4">Agree</option>
                <option value="5">Strongly agree</option>
              </Form.Control>
            </Form.Group>
          </Col>

          {/*----------------------------------------------------*/}
          <Col>
            <Form.Group controlId="maritalStatus">
              <Form.Label>Marital Status ?</Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="2">Single</option>
                <option value="1">Married</option>
              </Form.Control>
            </Form.Group>
            <br />
            <Form.Group controlId="incomeMode">
              <Form.Label>What is your mode of income?</Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">Salary</option>
                <option value="2">Owned Properties</option>
                <option value="3">Business</option>
                <option value="2">Profession</option>
                <option value="4">Diversified</option>
              </Form.Control>
            </Form.Group>
            <br />
            <Form.Group controlId="liabilities">
              <Form.Label>
                What percentage of your income goes into repayment of
                liabilities?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="1">More than 50%</option>
                <option value="2">50% to 30% </option>
                <option value="3">30% to 15%</option>
                <option value="4">Less than 15%</option>
                <option value="5">I have enough surplus</option>
              </Form.Control>
            </Form.Group>
            <br />
            <Form.Group controlId="knowledgeLevel">
              <Form.Label>
                Which of the following best describes your level of knowledge
                and understanding of financial markets and investing?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="4">High</option>
                <option value="3">Reasonable</option>
                <option value="2">Limited</option>
                <option value="1">Low</option>
                <option value="0">Don't know</option>
              </Form.Control>
            </Form.Group>
            <br />
            <Form.Group controlId="investmentDecisions">
              <Form.Label>
                If asked to make your own investment decisions, how would you
                feel?
              </Form.Label>
              <Form.Control as="select">
                <option value="">Please Select</option>
                <option value="0">Feels blank</option>
                <option value="1">Not at all confident</option>
                <option value="2">Somewhat hesitant</option>
                <option value="3">Reasonably confident</option>
                <option value="4">Very Confident</option>
              </Form.Control>
            </Form.Group>
            <br />
          </Col>
        </Row>
      </Form>
    );
  }

  function getRiskProfiles() {
    return (
      <Row>
        <Col>
          <div className="tool-container">
            <h4>Conservative Investor</h4>
            <br />
            Conservative risk profile refers to a considerably low-risk
            aptitude. Investors with this risk profile will bend towards
            investment options that offer the security of the corpus more than
            anything. The scale of returns is a secondary factor to conservative
            investors if it is not dangerous. Typically, a conventional risk
            profile accounts for a short period horizon.
            <br />
            <br />
            Investment options most suited for conservative or low-risk-takers
            are treasury bills, corporate bonds, sovereign bonds, debt-based
            mutual funds.
          </div>
        </Col>
        <Col>
          <div className="tool-container">
            <h4>Moderate Investor</h4>
            <br />
            Moderate risk-takers usually struggle to strike a balance between
            returns and risk. These types of individuals will go for high
            returns scaled to an acceptable level of risk. Therefore, a moderate
            risk-takers portfolio will constitute a satisfactory share of
            equities with debt instruments for adequate risk dilution.
            <br />
            <br />
            Such risk-takers can also singularly invest in equity-based mutual
            funds.
          </div>
        </Col>
        <Col>
          <div className="tool-container">
            <h4>Aggressive Investor</h4>
            <br />
            This risk profile exhibits the most readiness for withstanding
            market volatilities in the expectation of earning exponential
            returns. These investors are seasoned and well conversant in the
            ways of stock markets. These investors also have a long-term
            investment horizon, which is why they can stomach the short-term
            volatilities.
            <br />
            <br />
            These investors mostly go for equities and usually have a healthy
            asset-liability balance, and sometimes young individuals with
            sufficient disposable income also fit this investment risk profile.
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Header />
      <Container className="content">
        <h2 className="text-center">
          {showRiskCalculator ? "Risk Calculator" : "Risk Profiling"}
        </h2>
      </Container>

      <Container className="risk-profile">
        <Row>
          <Col>
            {showRiskCalculator ? (
              <p>
                The risk profiling questionnaire is meant to measure the risk
                tolerance as well as time horizon in investing. The
                questionnaire is designed to show which type of investment
                approach may suit you best. Each answer would be given a point.
                The total score would suggest the appropriate risk profile for
                you. There are not any right or wrong answers, please follow
                your instincts and answer the questions. Please answer the
                following questions by selecting only one response to each
                question.
              </p>
            ) : (
              <p>
                A <b>Risk Profiling</b> in simple words, can be said as the
                process of evaluating the ability or/and willingness of an
                individual to take risks concerning his/her investment
                decisions. This <b>Risk Profile</b> can be considered a tool for
                deciding about appropriate investment and asset allocation while
                creating a portfolio. Organizations and individuals with
                investment consciousness always wish to have a proper risk
                profile to mitigate huge threats and risks. This evaluation
                keenly identifies the acceptable level of risk an individual is
                prepared and able to accept. At the same time, it allows them to
                know about themselves and which category of investor they belong
                to.
              </p>
            )}
          </Col>
        </Row>
        <br />
        {showRiskCalculator ? (
          <></>
        ) : (
          <Row>
            <Col className="text-center">
              <Button
                variant="primary"
                onClick={(e) => {
                  setShowRiskCalculator(true);
                }}
              >
                Continue to Risk Calculator
              </Button>
            </Col>
          </Row>
        )}
        <br />
        {showRiskCalculator ? getRiskCalculator() : getRiskProfiles()}
      </Container>
      <Footer />
    </>
  );
}
export default RiskProfile;
